import {
  UPDATE_MARKET_FILTER,
  UPDATE_VARIABLE_FILTER,
  UPDATE_SOURCE_FILTER,
  UPDATE_AUDITOR_FILTER,
  LOADING_AUDIT_SUMMARY,
  UPDATE_IMPORTANCE_FILTER,
  UPDATE_DATE_FROM,
  UPDATE_DATE_TO,
  UPDATE_AUDIT_SEARCH,
  UPDATE_AUDIT_ALERTS_TYPE,
  UPDATE_AUDIT_ALERTS_STATUS,
} from "constants/action-types";
import moment from "moment";
import { DATE_FORMAT_DASH } from "constants/general";


const initialState = {
  selectedMarket: { name: 'All' },
  selectedVariable: { name: 'All' },
  selectedSource: 'All',
  selectedAuditor: 'All',
  dateFrom: moment.utc().add(-7, 'days').format(DATE_FORMAT_DASH), 
  dateTo: moment.utc().format(DATE_FORMAT_DASH),
  loading: true,
  importance: 'All',
  search: '',
  alertsType: 'All',
  alertsStatus: { name: 'All' },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MARKET_FILTER:
      return {
        ...state,
        selectedMarket: action.payload,
      };
    case UPDATE_VARIABLE_FILTER:
  	  return {
        ...state,
        selectedVariable: action.payload,
      };
    case UPDATE_SOURCE_FILTER:
      return {
        ...state,
        selectedSource: action.payload,
      }
    case UPDATE_AUDITOR_FILTER: 
      return {
        ...state,
        selectedAuditor: action.payload,
      }
    case LOADING_AUDIT_SUMMARY:
      return {
        ...state,
        loading: action.payload,
      }
    case UPDATE_IMPORTANCE_FILTER: 
      return {
        ...state,
        importance: action.payload,
      }
    case UPDATE_DATE_FROM:
      return {
        ...state,
        dateFrom: action.payload,
      }
    case UPDATE_DATE_TO:
      return {
        ...state,
        dateTo: action.payload,
      }
    case UPDATE_AUDIT_SEARCH:
      return {
        ...state,
        search: action.payload,
      }
    case UPDATE_AUDIT_ALERTS_TYPE: 
      return {
        ...state,
        alertsType: action.payload,
      }
    case UPDATE_AUDIT_ALERTS_STATUS:
      return {
        ...state,
        alertsStatus: action.payload,
      }
    default:
      return state;
  }
};
