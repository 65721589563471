import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from '@material-table/core';

import Grid from '@material-ui/core/Grid';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import LoginPage from 'views/Login/Oops.jsx';

import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';
import Plot from 'react-plotly.js';

import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

import {
  get_metrics_data,
  BULK_LOAD_PERIOD,
  resetMetricsDataAllLoaded,
  stopMetricsDataLoaded,
} from 'actions/index';
import { makeSeries, stocksChartOptions } from 'variables/charts';
import {
  zeroLineColor,
  lightPrimaryColor,
} from 'assets/jss/material-dashboard-react.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import 'moment-timezone';
import { calculateWAPE } from 'utils/kpi';

import { buildAPIRequest, security_fetch_params } from 'actions/index';
import localForage from 'localforage';
import { logout } from 'utils/auth';
import { primaryColor } from 'assets/jss/material-dashboard-react';

const colors = [
  '#6e0b2f',
  '#f07e0c',
  '#115e1b',
  '#4d1818',
  '#4363d8',
  '#3d4d42',
  '#b0774f',
  '#811df5',
  '#00008B',
  '#333131',
  '#253025',
  '#0288a6',
  '#5a7d02',
  '#f032e6',
]

const formTimeValueObject = (data, key) => {
  return data.map((x) => ({ time: x.time, value: x[key] })).sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0))
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    loading: state.metrics.loading,
    loadingChunks: state.metrics.loadingChunks,
    allDataLoaded: state.metrics.allDataLoaded,
    prices: state.metrics.prices.length
      ? Object.keys(state.metrics.prices[0])
      : [],
    forecast: formTimeValueObject(state.metrics.prices, 'forecast'),
    subDaForecast: formTimeValueObject(state.metrics.subDaPrices, 'forecast'),
    published: formTimeValueObject(state.metrics.prices, 'published'),
    subDaPublished: formTimeValueObject(state.metrics.subDaPrices, 'published'),
    reconstruction: formTimeValueObject(state.metrics.prices, 'Samawatt'),
    subDaReconstruction: formTimeValueObject(state.metrics.subDaPrices, 'Samawatt'),
    sun: formTimeValueObject(state.metrics.prices, 'Sun'),
    subDaSun: formTimeValueObject(state.metrics.subDaPrices, 'Sun'),
    wind: formTimeValueObject(state.metrics.prices, 'Wind'),
    subDaWind: formTimeValueObject(state.metrics.subDaPrices, 'Wind'),
    eq: formTimeValueObject(state.metrics.prices, 'EQ'),
    subDaEq: formTimeValueObject(state.metrics.subDaPrices, 'EQ'),
    netztransparenz: formTimeValueObject(state.metrics.prices, 'Netz'),
    subDaNetztransparenz: formTimeValueObject(state.metrics.subDaPrices, 'Netz'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_metrics_data: (data) => dispatch(get_metrics_data(data)),
  resetMetricsDataAllLoaded: (data) =>
    dispatch(resetMetricsDataAllLoaded(data)),
  stopMetricsDataLoaded: (data) => dispatch(stopMetricsDataLoaded(data)),
});

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    minHeight: '500px',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  sizeS: {
    left: 290,
    top: '1.75rem',
    width: 'auto',
    height: 'auto',
  },
  sideSpacing: {
    marginRight: '1.25rem',
  },
  spacingS: {
    margin: '0.625rem 0',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  textLeft: {
    textAlign: 'left',
  },
  bgColor: {
    backgroundColor: '#EEEEEE',
  },
};

const DAYS_BEFORE = 32;
const DAYS_AFTER = 2;
const ALL_DATA_ORDER = 5; // order in Highcharts options
const DATE_FORMAT_STR = 'YYYYMMDD';
const granArr = [1, 0.5, 0.25];

class MetricsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      market: 'DE',
      variable: {
        FR: 'pos',
        DE: 'back',
        GB: 'sngl',
        PL: 'sngl',
        'IT-73I': 'sngl',
        'IT-788': 'sngl',
      },
      date_from: moment().add(-DAYS_BEFORE, 'days'),
      date_to: moment().add(DAYS_AFTER, 'days'),
      markets: [
        { id: 'FR', name: 'France' },
        { id: 'DE', name: 'Germany' },
        { id: 'GB', name: 'United Kingdom'},
        { id: 'PL', name: 'Poland'},
        { id: 'IT-73I', name: 'Italy North'},
        { id: 'IT-788', name: 'Italy South'},
      ],
      variables: [
          {'id': 'pos', 'name': 'Forecast Imbalance price up', country: 'FR'},
          {'id': 'neg', 'name': 'Forecast Imbalance price down', country: 'FR'},
          {'id': 'back', 'name': 'Backcast Imbalance price', country: 'DE'},
          {'id': 'sngl', 'name': 'Forecast Imbalance price', country: 'DE'},
          {'id': 'sngl', 'name': 'Forecast Imbalance price', country: 'GB'},
          {'id': 'sngl', 'name': 'Forecast Imbalance price', country: 'PL'},
          {'id': 'sngl', 'name': 'Forecast Imbalance price', country: 'IT-73I'},
          {'id': 'sngl', 'name': 'Forecast Imbalance price', country: 'IT-788'},
      ],
      subtractDA: false,
      selectedPeriod: 0, // 0 means "1 month" in Chart Zoom controls
      positiveThreshold: 0.99,
      negativeThreshold: 0.01,
      spikeWindowDays: 30,
      granularity: 1,
      spikeData: null,
      serializeSpikeData: null,
      spikesPlotData: null,
      positiveHourWeekdayData: null,
      negHourWeekdayData: null,
      loading: false,
      spikesArr: null,
      posSpikes: null,
      negSpikes: null,
      dataInCSV: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.refresh = this.refresh.bind(this);
    this.calculateWAPEs = this.calculateWAPEs.bind(this);
    this.getSpikes = this.getSpikes.bind(this);

    this.chartRef = React.createRef();
    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsExportVisiblePeriod(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart, 'beforeShowResetZoom');
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Re-redner only on changing loading or state
    return (
      nextProps.loading !== this.props.loading ||
      nextProps.loadingChunks !== this.props.loadingChunks ||
      nextProps.prices !== this.props.prices ||
      nextState !== this.state
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // For some reason HighCharts lib doesn't make active selected period
    // button after data was loaded. Maybe, because of re-render
    if (
      ((!this.props.loadingChunks &&
        prevProps.loadingChunks &&
        this.state.selectedPeriod !== undefined) ||
        (!this.props.loading &&
          !this.props.loadingChunks &&
          prevProps.loading)) &&
      this.chartRef.current?.chart?.hasRendered &&
      this.state.selectedPeriod !== undefined
    ) {
      const { buttons } = this.chartRef.current.chart.rangeSelector;
      buttons[this.state.selectedPeriod].element.hcEvents.click[0].fn();
    }
    if (
      this.state.spikeData !== prevState.spikeData &&
      this.state.spikeData !== null
    ) {
      const data = this.state.spikeData.data;
      const [spikesPlot, spikesArr, posSpikes, negSpikes] =
        this.serializeSpikes(data.spikes_plot);
      this.setState({
        serializeSpikeData: [
          spikesPlot,
          this.serializeThreeDimData(data.positive_hour_weekday),
          this.serializeThreeDimData(data.negative_hour_weekday),
          this.serializeHours(data.positive_hour),
          this.serializeHours(data.negative_spike),
        ],
        spikesArr: spikesArr,
        posSpikes: posSpikes,
        negSpikes: negSpikes,
      });
    }
    
    if (
      prevState.market !== this.state.market ||
      JSON.stringify(prevState.variable) !==
        JSON.stringify(this.state.variable) ||
      // prevState.date_from.toString() !== this.state.date_from.toString() ||
      // prevState.date_to.toString() !== this.state.date_to.toString() ||
      prevState.granularity !== this.state.granularity
    ) {
      this.refresh();
    }
  }

  componentDidMount() {
    this.refresh();
  }

  handleChange(ev) {
    if (!ev.target.value) {
      return;
    }
    this.props.resetMetricsDataAllLoaded();
    let state = {
      selectedPeriod:
        ev.target.id === 'start_date' || ev.target.id === 'end_date'
          ? undefined
          : 1,
      date_from:
        ev.target.id === 'start_date'
          ? moment(ev.target.value)
          : this.state.date_from,
      date_to:
        ev.target.id === 'end_date'
          ? moment(ev.target.value)
          : this.state.date_to,
    };
    const key = ev.target.name;
    if (key === 'variable') {
      state = {
        ...state,
        [key]: {
          ...this.state.variable,
          [this.state.market]: ev.target.value,
        },
      };
    } else if (key === 'market'){
      // 'market' changed
      state = {
        ...state,
        [key]: ev.target.value,
      };
    }

    this.setState(state);
  }

  handleCheckboxChange() {
    this.setState(
      (state) => ({
        subtractDA: !state.subtractDA,
      }),
    );
  }

  refresh(isDownloadingByChunks = false) {
    this.props.get_metrics_data({
      market: this.state.market,
      variable: this.state.variable[this.state.market],
      date_from: this.state.date_from.format(DATE_FORMAT_STR),
      date_to: this.state.date_to.format(DATE_FORMAT_STR),
      subDA: '0',
      granularity: this.state.granularity,
      isDownloadingByChunks,
    });
    this.props.get_metrics_data({
      market: this.state.market,
      variable: this.state.variable[this.state.market],
      date_from: this.state.date_from.format(DATE_FORMAT_STR),
      date_to: this.state.date_to.format(DATE_FORMAT_STR),
      subDA: '1',
      granularity: this.state.granularity,
      isDownloadingByChunks,
    });
    this.getSpikes();
  }

  async getSpikes() {
    this.setState({ loading: true });
    const INTERVAL = 36000;
    const DATE_FORMAT_STR = 'YYYYMMDD';
    let date_to = this.state.date_to;
    if (date_to > moment()) date_to = moment();
    const localForgeVarString = `spikes_${
      this.state.market
    }_${this.state.date_from.format(DATE_FORMAT_STR)}_${date_to.format(
      DATE_FORMAT_STR
    )}_${this.state.positiveThreshold}_${this.state.negativeThreshold}_${
      this.state.spikeWindowDays
    }_${this.state.granularity}`;
    const urlString = `/api/spikes?country_code=${
      this.state.market
    }&start=${this.state.date_from.format(
      DATE_FORMAT_STR
    )}&end=${date_to.format(DATE_FORMAT_STR)}&positive_threshold=${
      this.state.positiveThreshold
    }&negative_threshold=${this.state.negativeThreshold}&spike_window_days=${
      this.state.spikeWindowDays
    }&dt=${this.state.granularity}`;
    const data = await localForage.getItem(localForgeVarString);
    if (!data || data.updated_at < moment().unix() - INTERVAL) {
      const [url, headers] = buildAPIRequest(urlString);
      const response = await fetch(url, {
        method: 'GET',
        headers,
        ...security_fetch_params,
      });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
      }
      const resJson = await response.json();
      resJson.updated_at = moment().unix();
      if (!resJson.error) {
        await localForage.setItem(localForgeVarString, resJson, (err) => {
          err && console.log(err);
        });
      } else {
        alertify.error("Response error");
        console.log(resJson.error);
        return
      }
      this.setState({ spikeData: resJson, loading: false });
    }
    if(data)
      this.setState({ spikeData: data, loading: false });
  }

  serializeSpikes = (data) => {
    const spikesPlot = JSON.parse(JSON.stringify(data));
    const spikesArr = [];
    const posSpikes = [];
    const negSpikes = [];
    spikesPlot.data.forEach((item, ind) => {
      if (typeof item.y === 'string') {
        item.y = item.y.replaceAll('nan', 0);
        item.y = JSON.parse(item.y.replace(/'/g, '"'));
      }

      const regex = /datetime\.datetime\((\d+), (\d+), (\d+), (\d+), (\d+)\)/g;
      const matches = item.x.match(regex);
      const dates = matches && matches.map((match, i) => {
        const [year, month, day, hour, minute] = match.match(/\d+/g);
        const dateInCET = moment.tz(
          [year, month - 1, day, hour, minute],
          'UTC'
        );
        const dateInUTC = dateInCET.utc().unix();
        if (ind === 1) {
          posSpikes.push([dateInUTC, item.y[i]]);
        }
        if (ind === 2) {
          negSpikes.push([dateInUTC, item.y[i]]);
        }
        return new Date(year, month - 1, day, hour, minute);
      });
      item.x = dates;

      if (ind === 0) {
        item.x.forEach((x, index) => {
          const year = x.getFullYear();
          const month = String(x.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it starts from 0
          const day = String(x.getDate()).padStart(2, '0');
          spikesArr.push([`${year}-${month}-${day}`, item.y[index]]);
        });
      }
    });
    return [spikesPlot, spikesArr, posSpikes, negSpikes];
  };

  serializeThreeDimData = (data) => {
    data.data[0].x = JSON.parse(data.data[0].x.replace(/'/g, '"'));
    data.data[0].y = JSON.parse(data.data[0].y.replace(/'/g, '"'));
    data.data[0].z = JSON.parse(data.data[0].z.replace(/'/g, '"'));
    return data;
  };

  serializeHours = (data) => {
    if (typeof data.data[0].x === 'string')
      data.data[0].x = JSON.parse(data.data[0].x.replace(/'/g, '"'));
    if (typeof data.data[0].y === 'string')
      data.data[0].y = JSON.parse(data.data[0].y.replace(/'/g, '"'));
    if (typeof data.data[0].hovertext === 'string')
      data.data[0].hovertext = JSON.parse(
        data.data[0].hovertext.replace(/'/g, '"')
      );
    return data;
  };

  calculateWAPEs(min, max) {
    const wape_forecast = calculateWAPE(
      this.state.subtractDA ? this.props.subDaPublished : this.props.published,
      this.state.subtractDA ? this.props.subDaForecast : this.props.forecast,
      min,
      max
    );

    const wape_reconstruction_samawatt = calculateWAPE(
      this.state.subtractDA ? this.props.subDaPublished : this.props.published,
      this.state.subtractDA ? this.props.subDaReconstruction : this.props.reconstruction,
      min,
      max
    );

    const wape_reconstruction_sun = calculateWAPE(
      this.state.subtractDA ? this.props.subDaPublished : this.props.published,
      this.state.subtractDA ? this.props.subDaSun : this.props.sun,
      min,
      max
    );

    const wape_reconstruction_wind = calculateWAPE(
      this.state.subtractDA ? this.props.subDaPublished : this.props.published,
      this.state.subtractDA ? this.props.subDaWind : this.props.wind,
      min,
      max
    );

    const wape_reconstruction_eq = calculateWAPE(
      this.state.subtractDA ? this.props.subDaPublished : this.props.published,
      this.state.subtractDA ? this.props.subDaEq : this.props.eq,
      min,
      max
    );

    const wape_netztransparenz = calculateWAPE(
      this.state.subtractDA ? this.props.subDaPublished : this.props.published,
      this.state.subtractDA ? this.props.subDaNetztransparenz : this.props.netztransparenz,
      min,
      max
    );

    return {
      Published: NaN,
      Forecast: wape_forecast,
      'Samawatt Reconstruction': wape_reconstruction_samawatt,
      Sun: wape_reconstruction_sun,
      EQ: wape_reconstruction_eq,
      Wind: wape_reconstruction_wind,
      Netztransparenz: wape_netztransparenz,
    };
  }

  calculateMinY() {
    const published = this.state.subtractDA ? this.props.subDaPublished : this.props.published;
    const forecast = this.state.subtractDA ? this.props.subDaForecast : this.props.forecast;
    const sun = this.state.subtractDA ? this.props.subDaSun : this.props.sun;
    const reconstruction = this.state.subtractDA ? this.props.subDaReconstruction : this.props.reconstruction;
    const eq = this.state.subtractDA ? this.props.subDaEq : this.props.eq;
    const wind = this.state.subtractDA ? this.props.subDaWind : this.props.wind;

    //Calculate min y for area
    const minY = [];
    const minSun = Math.min(...sun.map((z) => z.value));
    const minForecast = Math.min(...forecast.map((z) => z.value));
    const minPrices = Math.min(...this.props.prices.map((z) => z.value));
    const minPublished = Math.min(...published.map((z) => z.value));
    const minReconstr = Math.min(...reconstruction.map((z) => z.value));
    const minEq = Math.min(...eq.map((z) => z.value));
    const minWind = Math.min(...wind.map((z) => z.value));
    if (!isNaN(minForecast)) minY.push(minForecast);
    if (!isNaN(minPrices)) minY.push(minPrices);
    if (!isNaN(minPublished)) minY.push(minPublished);
    if (!isNaN(minReconstr)) minY.push(minReconstr);
    if (!isNaN(minEq)) minY.push(minEq);
    if (!isNaN(minWind)) minY.push(minWind);
    if (!isNaN(minSun)) minY.push(minSun);
    return minY.reduce((a, b) => Math.min(a, b));
  }

  render() {
    const minX = this.state.date_from.unix() * 1000;
    const maxX = this.state.date_to.unix() * 1000;
    const wapes = this.calculateWAPEs(minX, maxX);

    // Note: Use regular function instead of arrow one
    // to have TooltipFormatterContext as this
    function priceTooltipFormatter() {
      const xLabel = moment.tz(this.x, 'UTC').format('YYYY-MM-DD HH:mm');
      let tooltip = `<span style="font-size: 10px">${xLabel}</span><br/>`;
      const points = this.points ?? [];
      points.forEach((point) => {
        tooltip +=
          '<span style="color:' +
          point.color +
          '">\u25CF</span> ' +
          point.series.name +
          ': <b>' +
          point.y.toFixed(2) +
          '</b><br/>';
      });
      return tooltip;
    }

    const that = this;
    const handleTimePeriodClick = function () {
      const date_from =
        this.type === 'ytd'
          ? moment(`${new Date().getFullYear()}-01-01`)
          : moment().add(-this.count, this.type);
      if (
        !that.props.loading &&
        !that.props.loadingChunks &&
        that.state.date_from > date_from &&
        !that.props.allDataLoaded
      ) {
        that.setState(
          {
            date_from,
            date_to: moment().add(DAYS_AFTER, 'days'),
            selectedPeriod: this.order,
          },
          () => that.refresh()
        );
      }
      return true;
    };

    const handleTimePeriodAllClick = function () {
      that.props.stopMetricsDataLoaded(false);
      if (
        !that.props.loading &&
        !that.props.loadingChunks &&
        !that.props.allDataLoaded
      ) {
        const date_from = moment().add(-BULK_LOAD_PERIOD, 'months');
        that.setState(
          {
            date_from,
            date_to: moment().add(DAYS_AFTER, 'days'),
            selectedPeriod: this.order,
          },
          () => that.refresh(true)
        );
      }
      return true;
    };

    const splitSeries = (name, data, posColor, negColor, visible = true) => {
      return [
        makeSeries(
          `${name} positive`,
          data.map((z) => ({ x: z.time, y: z.value >= 0 ? z.value : null })),
          { turboThreshold: 99999999, color: posColor, visible }
        ),
        makeSeries(
         `${name} negative`,
          data.map((z) => ({ x: z.time, y: z.value < 0 ? z.value : null })),
          { turboThreshold: 99999999, color: negColor, yAxis: 1, visible }
        ),
      ]
    }
    
    const options = {
      ...stocksChartOptions({
        filename: this.state.variable[this.state.market],
        legend: {
          itemMarginTop: 16,
          itemWidth: 210,
          labelFormatter: function () {
            return !Number.isNaN(wapes[this.name]) && this.visible
              ? `${this.name}<br>MAPE: ${wapes[this.name].toFixed(2)}%`
              : `${this.name}`;
          },
        },
        events: {
          redraw: function (e) {
            const { min, max } = this.xAxis[0];
            const updatedWAPEs = that.calculateWAPEs(min, max);

            this.series.forEach((s) => {
              if (s.legendItem?.element) {
                const value =
                  s.visible && !Number.isNaN(updatedWAPEs[s.name])
                    ? `</tspan>MAPE: ${updatedWAPEs[s.name].toFixed(2)}%`
                    : '</tspan>';
                s.legendItem.element.innerHTML =
                  s.legendItem.element.innerHTML.replace(
                    /<\/tspan>(MAPE: \d+\.?\d+%)?/g,
                    value
                  );
              }
            });
          },
        },
        rangeSelector: {
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month',
              order: 0,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months',
              order: 1,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months',
              order: 2,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date',
              order: 3,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year',
              order: 4,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'all',
              text: 'All',
              title: 'View all',
              order: ALL_DATA_ORDER,
              events: {
                click: handleTimePeriodAllClick,
              },
            },
          ],
          selected: this.state.selectedPeriod,
        },
      }),
      yAxis: [
        {
          title: {
            text: 'value',
          },
          // Add color area below 0 y axis
          plotBands: this.state.subtractDA
            ? [
                {
                  color: lightPrimaryColor,
                  from: this.calculateMinY(), // Start of the plot band
                  to: 0, // End of the plot band
                },
              ]
            : null,
          // Add grey line at 0 y axis
          plotLines: this.state.subtractDA
            ? [
                {
                  color: zeroLineColor,
                  value: 0, // Value of where the line will appear
                  width: 5, // Width of the line
                },
              ]
            : null,
        },
      ],
      xAxis: {
        title: {
          text: "Time, CET",
        }
      },
      series: [
        makeSeries(
          'Published',
          (this.state.subtractDA ? this.props.subDaPublished : this.props.published).map((z) => ({ x: z.time, y: z.value })),
          { turboThreshold: 99999999, colorIndex: 1 }
        ),
        ...(this.props.prices.includes('forecast')
          ? [
              makeSeries(
                'Forecast',
                (this.state.subtractDA ? this.props.subDaForecast : this.props.forecast).map((z) => ({ x: z.time, y: z.value })),
                { turboThreshold: 99999999, colorIndex: 0 }
              ),
            ]
          : []),
        ...(this.props.prices.includes('Samawatt')
          ? [
              makeSeries(
                'Samawatt Reconstruction',
                (this.state.subtractDA ? this.props.subDaReconstruction : this.props.reconstruction).map((z) => ({
                  x: z.time,
                  y: z.value,
                })),
                { turboThreshold: 99999999, colorIndex: 2 }
              ),
            ]
          : []),
        ...(this.props.prices.includes('Sun')
          ? [
              makeSeries(
                'Sun',
                (this.state.subtractDA ? this.props.subDaSun : this.props.sun).map((z) => ({ x: z.time, y: z.value })),
                {
                  turboThreshold: 99999999,
                  visible: false,
                  colorIndex: 3,
                }
              ),
            ]
          : []),
        ...(this.props.prices.includes('Wind')
          ? [
              makeSeries(
                'Wind',
                (this.state.subtractDA ? this.props.subDaWind : this.props.wind).map((z) => ({ x: z.time, y: z.value })),
                {
                  turboThreshold: 99999999,
                  visible: false,
                  colorIndex: 0,
                }
              ),
            ]
          : []),
        ...(this.props.prices.includes('EQ')
          ? [
              makeSeries(
                'EQ',
                (this.state.subtractDA ? this.props.subDaEq : this.props.eq).map((z) => ({ x: z.time, y: z.value })),
                {
                  turboThreshold: 99999999,
                  visible: false,
                  colorIndex: 5,
                }
              ),
            ]
          : []),
        ...(this.props.prices.includes('Netz')
          ? [
              makeSeries(
                'Netztransparenz',
                (this.state.subtractDA ? this.props.subDaNetztransparenz : this.props.netztransparenz).map((z) => ({
                  x: z.time,
                  y: z.value,
                })),
                {
                  turboThreshold: 99999999,
                  visible: false,
                  colorIndex: 6,
                }
              ),
            ]
          : []),
      ],
      tooltip: {
        shared: true,
        formatter: priceTooltipFormatter,
      },
    };

    const calcYMinMax = (dateMin, dateMax) => {
      const published = this.state.subtractDA ? this.props.subDaPublished : this.props.published;
      const forecast = this.state.subtractDA ? this.props.subDaForecast : this.props.forecast;
      const sun = this.state.subtractDA ? this.props.subDaSun : this.props.sun;
      const reconstruction = this.state.subtractDA ? this.props.subDaReconstruction : this.props.reconstruction;
      const eq = this.state.subtractDA ? this.props.subDaEq : this.props.eq;
      const wind = this.state.subtractDA ? this.props.subDaWind : this.props.wind;

      const maxValues = [published, forecast, sun, reconstruction, eq, wind].map(arr => {
        let filtered = [...arr];
        if (dateMin && dateMax) {
          filtered = filtered.filter(obj => obj.time > dateMin && obj.time < dateMax);
        }
        return Math.max(...filtered.map(obj => obj.value ? Math.abs(obj.value) : 0))
      });
      
      return Math.max(...maxValues)
    }
    
    const splittedOptions = {
      ...stocksChartOptions({
        filename: this.state.variable[this.state.market],
        chart: {
          animation: false,
          zoomType: 'x',
          backgroundColor: 'transparent',
          height: 700,
        },
        xAxis: {
          events: {
            setExtremes(e) {
              const minDate = e.min;
              const maxDate = e.max;
              const value = calcYMinMax(minDate, maxDate);
              this.chart.yAxis[0].setExtremes(0, value);
              this.chart.yAxis[1].setExtremes(`-${value}`, -0.01);
            }
          }
        },
        rangeSelector: {
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month',
              order: 0,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months',
              order: 1,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months',
              order: 2,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date',
              order: 3,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year',
              order: 4,
              events: {
                click: handleTimePeriodClick,
              },
            },
            {
              type: 'all',
              text: 'All',
              title: 'View all',
              order: ALL_DATA_ORDER,
              events: {
                click: handleTimePeriodAllClick,
              },
            },
          ],
          selected: this.state.selectedPeriod,
        },
      }),
      yAxis: [
        {
          title: {
            text: 'Positive value',
          },
          min: 0,
          max: calcYMinMax(),
          height: '45%',
          offset: 0,
        },
        {
          title: {
            text: 'Negative value'
          },
          height: '45%',
          top: '45%',
          max: -0.01,
          min: `-${calcYMinMax()}`
        }
      ],
      series: [
        ...splitSeries(
          'Published',
          this.state.subtractDA 
            ? this.props.subDaPublished 
            : this.props.published,
          colors[0],
          colors[1]
        ),
        ...(this.props.prices.includes('forecast')
          ? [
              ...splitSeries(
                'Forecast',
                this.state.subtractDA 
                  ? this.props.subDaForecast
                  : this.props.forecast,
                  colors[2],
                  colors[3]
              ),
            ]
          : []),
        ...(this.props.prices.includes('Samawatt')
          ? [
              ...splitSeries(
                'Samawatt Reconstruction',
                this.state.subtractDA 
                  ? this.props.subDaReconstruction 
                  : this.props.reconstruction,
                colors[4],
                colors[5]
              ),
            ]
          : []),
        ...(this.props.prices.includes('Sun')
          ? [
              ...splitSeries(
                'Sun',
                this.state.subtractDA 
                  ? this.props.subDaSun 
                  : this.props.sun,
                colors[6],
                colors[7],
                false,
              ),
            ]
          : []),
        ...(this.props.prices.includes('Wind')
          ? [
              ...splitSeries(
                'Wind',
                this.state.subtractDA 
                  ? this.props.subDaWind 
                  : this.props.wind,
                colors[8],
                colors[9],
                false,
              ),
            ]
          : []),
        ...(this.props.prices.includes('EQ')
          ? [
              ...splitSeries(
                'Eq',
                this.state.subtractDA 
                  ? this.props.subDaEq 
                  : this.props.eq,
                colors[10],
                colors[11],
                false,
              ),
            ]
          : []),
        ...(this.props.prices.includes('Netz')
          ? [
              ...splitSeries(
                'Netztransparenz',
                this.state.subtractDA 
                  ? this.props.subDaNetztransparenz 
                  : this.props.netztransparenz,
                colors[12],
                colors[13],
                false,
              ),
            ]
          : []),
      ],
      tooltip: {
        shared: true,
        formatter: priceTooltipFormatter,
      },
    };

    const { classes } = this.props;

    const columns = [
      {
        title: 'Date, CET',
        field: 'date',
        type: 'datetime',
        filtering: false,
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'DA',
        field: 'da',
        type: 'numeric',
        filtering: false,
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'IMB',
        field: 'imb',
        type: 'numeric',
        filtering: false,
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'IMB-DA',
        field: 'imb_da',
        type: 'numeric',
        filtering: false,
        cellStyle: { textAlign: 'center' },
      },
    ];
    const spikesPos = [];
    const spikesNeg = [];
    const posSpikesImb = [];
    const negSpikesImb = [];
    const headerStyle = {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: primaryColor,
      fontWeight: 500,
      fontSize: '1em',
      padding: '12px 0',
      textAlign: 'center',
    };
    if (this.props.netztransparenz && this.props.published) {
      if (this.state.posSpikes) {
        this.state.posSpikes.forEach((element) => {
          const timeStamp = element[0];
          const value = element[1];
          const date = moment
            .tz(timeStamp * 1000, 'UTC')
            .format('YYYY-MM-DD HH:mm');
          
          const pubVal = this.props.published.find(
            (x) => x.time === (timeStamp) * 1000
          )?.['value'];
          const netzVal = this.props.netztransparenz.find(
            (x) => x.time === (timeStamp) * 1000
          )?.['value'];
          let imbVal = '';
          if (pubVal) {
            posSpikesImb.push([timeStamp, pubVal]);
            imbVal = pubVal;
          } else if (netzVal) {
            posSpikesImb.push([timeStamp, netzVal]);
            imbVal = netzVal;
          }
          spikesPos.push({
            date: date,
            imb_da: value.toFixed(2),
            imb: Number(imbVal).toFixed(2),
            da: (imbVal - value).toFixed(2),
          });
        });
        spikesPos.push({
          date: 'Total',
          imb_da: '',
          imb: '',
          da: this.state.posSpikes.length,
        });
      }

      if (this.state.negSpikes) {
        this.state.negSpikes.forEach((element) => {
          const timeStamp = element[0];
          const value = element[1];
          const date = moment
            .tz(timeStamp * 1000, 'UTC')
            .format('YYYY-MM-DD HH:mm');

          const pubVal = this.props.published.find(
            (x) => x.time === (timeStamp) * 1000
          )?.['value'];
          const netzVal = this.props.netztransparenz.find(
            (x) => x.time === (timeStamp) * 1000
          )?.['value'];
          let imbVal = '';
          if (pubVal) {
            negSpikesImb.push([timeStamp, pubVal]);
            imbVal = pubVal;
          } else if (netzVal) {
            negSpikesImb.push([timeStamp, netzVal]);
            imbVal = netzVal;
          }
          spikesNeg.push({
            date: date,
            imb_da: value.toFixed(2),
            imb: Number(imbVal).toFixed(2),
            da: (imbVal - value).toFixed(2),
          });
        });
        spikesNeg.push({
          date: 'Total',
          imb_da: '',
          imb: '',
          da: this.state.negSpikes.length,
        });
      }
    }
    const loader = (
      <div
        className={
          this.props?.published.length
            ? classNames('loader', 'sizeS')
            : 'loader'
        }
        alt="Loading report..."
      />
    );

    if (this.props?.isLoggedIn) {
      if (this.state.markets?.length > 0) {
        return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card style={{ backgroundColor: '#EEEEEE' }}>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Market Imbalance prices</h4>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={2} className={classes.flex}>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <form noValidate>
                        <TextField
                          id="start_date"
                          label="Start date"
                          type="date"
                          name="start_date"
                          // defaultValue={that.state.date_from.format("YYYY-MM-DD")}
                          value={that.state.date_from.format('YYYY-MM-DD')}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            max: that.state.date_to.format('YYYY-MM-DD'),
                          }}
                        />
                      </form>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <form noValidate>
                        <TextField
                          id="end_date"
                          label="End date"
                          type="date"
                          name="end_date"
                          // defaultValue={that.state.date_to.format("YYYY-MM-DD")}
                          value={that.state.date_to.format('YYYY-MM-DD')}
                          onChange={this.handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: that.state.date_from.format('YYYY-MM-DD'),
                          }}
                        />
                      </form>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <FormControl
                        className={classNames(
                          classes.formControl,
                          classes.sideSpacing
                        )}
                      >
                        <InputLabel htmlFor="market_id">Market</InputLabel>
                        <Select
                          native
                          value={this.state.market}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'market',
                            id: 'market_id',
                          }}
                        >
                          {this.state.markets.map((x, index) => {
                            return (
                              <option key={'opt' + index} value={x.id}>
                                {x.name}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <FormControl
                        className={classNames(
                          classes.formControl,
                          classes.sideSpacing
                        )}
                      >
                        <InputLabel htmlFor="variable_id">
                          Variable Name
                        </InputLabel>
                        <Select
                          native
                          value={this.state.variable[this.state.market]}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'variable',
                            id: 'variable_id',
                          }}
                        >
                          {this.state.variables
                            .filter((x) => x.country === this.state.market)
                            .map((x, index) => (
                              <option key={`opt${index}`} value={x.id}>
                                {x.name}
                              </option>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl className={classNames(classes.fullWidth)}>
                        <InputLabel
                          className={this.props.classes.selectLabel}
                          htmlFor="mode"
                        >
                          Granularity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={this.state.granularity}
                          onChange={(e) => {
                            this.setState({ granularity: e.target.value });
                          }}
                          autoWidth
                          label="Age"
                        >
                          {granArr.map((el, id) => {
                            return (
                              <MenuItem key={id} value={el}>
                                {el}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            id="subtractDA"
                            onChange={this.handleCheckboxChange}
                            checked={this.state.subtractDA}
                            value="subtractDA"
                          />
                        }
                        className={classNames(
                          classes.noWrap,
                          classes.sideSpacing
                        )}
                        label="Subtract DA prices"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <FormControl className={classes.formControl}>
                        <Button color="primary" onClick={() => this.refresh()}>
                          Refresh
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <FormControl className={classes.formControl}>
                        <Button color="primary" onClick={() => this.refresh()}>
                          Build
                        </Button>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      lg={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <FormControl className={classes.formControl}>
                        <Button
                          color="primary"
                          onClick={() => {
                            this.props.stopMetricsDataLoaded(true);
                            this.setState({
                              selectedPeriod: 1,
                            });
                          }}
                        >
                          Stop Loading
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
            {!this.props.loading && !this.props?.published.length > 0 ? null : (
              <Grid item xs={12}>
                <Card style={{ backgroundColor: '#EEEEEE' }}>
                  <CardBody style={{ backgroundColor: '#EEEEEE' }}>
                    <div className={classes.container}>
                      {(this.props.loading || this.props.loadingChunks) && (
                        <div
                          className={
                            this.props?.published.length
                              ? classNames(
                                  classes.loadingContainer,
                                  classes.sizeS
                                )
                              : classes.loadingContainer
                          }
                        >
                          {loader}
                        </div>
                      )}
                      {this.props?.published.length > 0 && this.props?.subDaPublished.length > 0 && (
                        <HighchartsReact
                          ref={this.chartRef}
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={options}
                        />
                      )}
                      {this.props?.published.length > 0 && this.props?.subDaPublished.length > 0 && (
                        <HighchartsReact
                          ref={this.chartRef}
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={splittedOptions}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Grid>
            )}
            <Grid item xs={12}>
              <Card style={{ backgroundColor: '#EEEEEE' }}>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Spike analysis</h4>
                </CardHeader>
                <CardBody style={{ backgroundColor: '#EEEEEE' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className={classNames(
                          classes.formControl,
                          classes.fullWidth,
                          classes.marginBottom
                        )}
                      >
                        <TextField
                          type="decimal"
                          label="Positive Threshold:"
                          variant="standard"
                          onChange={(e) => {
                            this.setState({
                              positiveThreshold: e.target.value,
                            });
                          }}
                          value={this.state.positiveThreshold}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className={classNames(
                          classes.formControl,
                          classes.fullWidth,
                          classes.marginBottom
                        )}
                      >
                        <TextField
                          type="decimal"
                          label="Negative Threshold:"
                          variant="standard"
                          onChange={(e) => {
                            this.setState({
                              negativeThreshold: e.target.value,
                            });
                          }}
                          value={this.state.negativeThreshold}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className={classNames(
                          classes.formControl,
                          classes.fullWidth,
                          classes.marginBottom
                        )}
                      >
                        <TextField
                          type="number"
                          label="Spike window days:"
                          variant="standard"
                          onChange={(e) => {
                            this.setState({ spikeWindowDays: e.target.value });
                          }}
                          value={this.state.spikeWindowDays}
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      className={classNames(classes.textLeft, classes.flex)}
                    >
                      <FormControl className={classes.formControl}>
                        <Button
                          color="primary"
                          onClick={() => this.getSpikes()}
                        >
                          Refresh
                        </Button>
                      </FormControl>
                    </Grid>
                    {this.state.spikesArr && this.state.spikeData?.csv ? (
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        className={classNames(classes.textLeft, classes.flex)}
                      >
                        <Button color="primary">
                          <a
                            href={`data:text/csv;charset=utf-8,${escape(
                              this.state.spikeData.csv
                            )}`}
                            download="filename.csv"
                            style={{ color: 'white' }}
                          >
                            Download CSV
                          </a>
                        </Button>
                      </Grid>
                    ) : <></>}
                  </Grid>
                </CardBody>
              </Card>
              <Card style={{ backgroundColor: '#EEEEEE' }}>
                <CardBody style={{ backgroundColor: '#EEEEEE' }}>
                  <div>
                    {this.state.serializeSpikeData &&
                    !this.props.loading &&
                    !this.state.loading ? (
                      this.state.serializeSpikeData.map((data, index) => {
                        return (
                          <Plot
                            key={`plot${index}`}
                            data={data.data}
                            layout={data.layout}
                            style={{ width: '1000px', height: '500px' }}
                          />
                        );
                      })
                    ) : (
                      <div className="loader" alt="Loading report..." />
                    )}
                  </div>
                  <MaterialTable
                    columns={columns}
                    data={spikesNeg}
                    title={'Negative Spikes'}
                    options={{
                      tableLayout: 'fixed',
                      paging: false,
                      maxBodyHeight: window.innerHeight - 280,
                      headerStyle: headerStyle,
                      emptyRowsWhenPaging: false,
                      pageSize: 6,
                      draggable: false,
                      search: false,
                    }}
                  />
                  <MaterialTable
                    columns={columns}
                    data={spikesPos}
                    title={'Positive Spikes'}
                    options={{
                      tableLayout: 'fixed',
                      paging: false,
                      maxBodyHeight: window.innerHeight - 280,
                      headerStyle: headerStyle,
                      emptyRowsWhenPaging: false,
                      pageSize: 6,
                      draggable: false,
                      search: false,
                    }}
                  />
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        );
      } else {
        return <div />;
      }
    } else {
      return <LoginPage />;
    }
  }
}

const ConnectedMetrics = connect(
  mapStateToProps,
  mapDispatchToProps
)(MetricsPage);
export default withStyles(styles)(ConnectedMetrics);
