import { 
  RECEIVED_REPORT, 
  RECEIVED_PNL_PERIOD_PARKS_DATA, 
  UPDATE_OPTIMISATION_TYPE,
  UPDATE_PNL_INTERVAL_CURRENCY_TYPE,
} from "../constants/action-types";

import { RECEIVED_DASHBOARD_DATA } from "../constants/action-types";

import { RECEIVED_DATADASHBOARD_DATA } from "../constants/action-types";

import { RECEIVED_MARKETSDATA_DATA } from "../constants/action-types";

import { RECEIVED_OPTIMISATION_DATA } from "../constants/action-types";

import { RECEIVED_RTRADER_DATA } from "../constants/action-types";

import { RECEIVED_RTRADER_MARKETDEPTH } from "../constants/action-types";

import { RECEIVED_RTRADER_MARKETTRADES } from "../constants/action-types";

import { RECEIVED_FUNDAMENTALS_AUDIT } from "../constants/action-types";

import { RECEIVED_FUNDAMENTALS_CURVE } from "../constants/action-types";

import { RECEIVED_MARKET_SCORE } from "../constants/action-types";

import { RECEIVED_TRADESDASHBOARD_DATA } from "../constants/action-types";

import {
  RECEIVED_GENERATION_DATA,
  RECEIVED_GENERATION_DATA_ALL,
  RECEIVED_GENERATION_DATA_CHUNK,
  LOADING_GENERATION_DATA,
  LOADING_CHUNKS_GENERATION_DATA,
  RESET_GENERATION_DATA_ALL_LOADED,
} from "../constants/action-types";

import { RECEIVED_JOBS_DATA } from "../constants/action-types";

import { RECEIVED_NOTIFICATIONS_DATA } from "../constants/action-types";

import { RECEIVED_MARKETS_DATA } from "../constants/action-types";

import {
  RECEIVED_METRICS_DATA,
  RECEIVED_METRICS_DATA_ALL,
  RECEIVED_METRICS_DATA_NOT_ALL,
  RECEIVED_METRICS_DATA_CHUNK,
  RECEIVED_SUB_DA_METRICS_DATA,
  RECEIVED_SUB_DA_METRICS_DATA_ALL,
  RECEIVED_SUB_DA_METRICS_DATA_NOT_ALL,
  RECEIVED_SUB_DA_METRICS_DATA_CHUNK,
  RESET_METRICS_DATA_ALL_LOADED,
  LOADING_METRICS_DATA,
  LOADING_CHUNKS_METRICS_DATA,
  STOP_METRICS_DATA,
} from "../constants/action-types";

import {
  LOADING_PNL_ANALYSIS_DATA,
  RECEIVED_PNL_ANALYSIS_DATA,
  PRELOADING_PNL_ANALYSIS_DATA,
  PRELOADED_PNL_ANALYSIS_DATA,
  LOADING_PARKS_DATA,
  RECEIVED_PNL_ANALYS_PARKS_DATA,
  ERROR_PRELOADING_PNL_ANALYSIS_DATA,
  RECEIVED_TOP_STRATEGIES_DATA
} from "../constants/action-types";

import { 
  RECEIVED_MODELS_DATA,
  LOADING_MODEL_DATA
} from "constants/action-types";

import { 
  RECEIVED_CATEGORIES,
  LOADING_CATEGORIES
} from "constants/action-types";

import { RECEIVED_STRUCTURED_ALERTS } from "../constants/action-types";

import { RECEIVED_COMPL_EXCS } from "../constants/action-types";

import { LOADING_REPORT } from "../constants/action-types";

import { LOADING_DASHBOARD_DATA } from "../constants/action-types";

import { LOADING_DATADASHBOARD_DATA } from "../constants/action-types";

import { LOADING_OPTIMISATION_DATA } from "../constants/action-types";

import { LOADING_RTRADER_DATA } from "../constants/action-types";

import { LOADING_RTRADER_MARKETDEPTH } from "../constants/action-types";

import { LOADING_RTRADER_MARKETTRADES } from "../constants/action-types";

import { LOADING_FUNDAMENTALS_AUDIT } from "../constants/action-types";

import { LOADING_FUNDAMENTALS_CURVE } from "../constants/action-types";

import { LOADING_TRADESDASHBOARD_DATA } from "../constants/action-types";

import { LOADING_JOBS_DATA } from "../constants/action-types";

import { LOADING_NOTIFICATIONS_DATA } from "../constants/action-types";

import { LOADING_MARKETS_DATA } from "../constants/action-types";

import { LOADING_STRUCTURED_ALERTS } from "../constants/action-types";

import { LOADING_COMPL_EXCS } from "../constants/action-types";

import { REFRESH_GW_TOKEN } from "../constants/action-types";

import { LOGGED_IN } from "../constants/action-types";

import { UINFO } from "../constants/action-types";

import { TARGETS } from "../constants/action-types";

import { UPDATE_PARKS_INFO } from "../constants/action-types";

import { UPDATE_ACTIVE_PARK} from "../constants/action-types";

import { UPDATE_CURRENT_PAGE} from "../constants/action-types";

import { UPDATE_CURRENT_REGION} from "../constants/action-types";

import { UPDATE_CURRENT_ANALYS_MARKET} from "../constants/action-types";

import { CHANGE_VISIBLE_ROUTES } from "../constants/action-types";

import {CHANGE_CONNECTION} from "../constants/action-types";

import { UPDATE_PNL_CURRENT_SETTINGS, UPDATE_BATTERY_TRADING_SETTINGS } from "../constants/action-types";
import { UPDATE_LOGIN_INSTANCES } from "constants/action-types";

import { 
  UPDATE_MARKET_FILTER, 
  UPDATE_VARIABLE_FILTER, 
  UPDATE_SOURCE_FILTER, 
  UPDATE_AUDITOR_FILTER, 
  LOADING_AUDIT_SUMMARY,
  UPDATE_IMPORTANCE_FILTER,
  UPDATE_DATE_FROM,
  UPDATE_DATE_TO,
  UPDATE_AUDIT_SEARCH,
  UPDATE_AUDIT_ALERTS_TYPE,
  UPDATE_AUDIT_ALERTS_STATUS,
} from "constants/action-types";

import { SHOW_OPTIMISATION_NON_ACTIVE } from "constants/action-types";

import configureStore from "configureStore.js";
import sprintf from "sprintf-js";
import jwt from 'jsonwebtoken';
import localForage from 'localforage';
import moment from 'moment';
import 'moment-timezone';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import {
  DEFAULT_PAGE_SIZE,
  ACCOUNTS_API_PORT,
} from 'constants/general';

import {
  oneDimVol,
  oneDimDaPriceSam,
  resampleIdTrades,
  calcGranData,
  oneDimVolGran,
  resampleArrToGran,
  oneDimDaPriceSamGran,
} from 'utils/calcFunctions';
import {logout} from 'utils/auth';


export const BULK_LOAD_PERIOD = 6;

function buildRows(data) {
  var da_optimisation = [];
    
  if (
    data.da_trades === null ||
    data.report === undefined ||
    data.report === null
  ) {
    return {
      tableDetailsHead: [],
    };
  }
  if (Object.keys(data.report).length === 0) {
    return {
      tableDetailsHead: [],
    };
  }
  const daDt = data.parameters.dt.length ? data.parameters.dt : [1];
  const idDt = data.parameters.id_dt;
  const ibDt = data.parameters.ib_dt;
  const uniqueDt = [...daDt, ibDt, 1].filter(
    (item, i, ar) => ar.indexOf(item) === i
  );

  if(!data.da_trades.length || !data.da_trades[0].length) {
    if (data.da_trades.length && !data.da_trades[0].length) {
      data.da_trades = [Array(24 / daDt[0]).fill(0)];
      data.da_prices = [Array(24 / daDt[0]).fill(0)];
      data.report.da_volumes_samawatt = JSON.stringify([Array(24 / daDt[0]).fill(0)]);
      data.report.da_samawatt_detail  = JSON.stringify([Array(24 / daDt[0]).fill(0)]);
    } else {
      data.da_trades = [Array(data.imbalance_prices.IBU.length * ibDt).fill(0)];
      data.da_prices = [Array(data.imbalance_prices.IBU.length * ibDt).fill(0)];
      data.report.da_volumes_samawatt = JSON.stringify([Array(data.imbalance_prices.IBU.length * ibDt).fill(0)]);
      data.report.da_samawatt_detail  = JSON.stringify([Array(data.imbalance_prices.IBU.length * ibDt).fill(0)]);
    }
  }

  const daTrades = [...data.da_trades];
  const daPrices = [...data.da_prices];
  const daVolumesSamawatt = JSON.parse(data.report.da_volumes_samawatt);
  const daSamawattDetails = JSON.parse(data.report.da_samawatt_detail);

  data.granDaVolumes = {};
  data.granDaPrices = {};
  data.granDaVolumesSamawatt = {};
  data.granDaSamawattDetails = {};

  daTrades.forEach((auctionDaData, id) => {
    data.granDaVolumes[id] = {};
    data.granDaPrices[id] = {};
    data.granDaVolumesSamawatt[id] = {};
    data.granDaSamawattDetails[id] = {};

    // could be no auction data thats why we need to check
    const curDaDt = daDt.length ? Number(daDt[id]) : 1;

    data.granDaVolumes[id][curDaDt] = auctionDaData;
    data.granDaPrices[id][curDaDt] = daPrices[id];
    data.granDaVolumesSamawatt[id][curDaDt] = daVolumesSamawatt[id];
    data.granDaSamawattDetails[id][curDaDt] = daSamawattDetails[id];
    
    uniqueDt.forEach((gran) => {
      if (gran === daDt[id]) return;
      data.granDaVolumes[id][gran] = calcGranData(
        auctionDaData,
        daDt[id],
        gran,
        'vol'
      );
      data.granDaPrices[id][gran] = calcGranData(
        daPrices[id],
        daDt[id],
        gran,
        'price'
      );
      data.granDaVolumesSamawatt[id][gran] = calcGranData(
        daVolumesSamawatt[id],
        daDt[id],
        gran,
        'vol'
      );
      data.granDaSamawattDetails[id][gran] = calcGranData(
        daSamawattDetails[id],
        daDt[id],
        gran,
        'vol'
      );
    });

    // Fill empty granularities with 0 if no volumes and prices
    Object.keys(data.granDaVolumes[id]).forEach((gran) => {
      if (!data.granDaPrices[id][gran].length) {
        data.granDaVolumes[id][gran] = Array(data.imbalance_prices.IBU.length * ibDt / Number(gran)).fill(0);
        data.granDaPrices[id][gran] = Array(data.imbalance_prices.IBU.length * ibDt / Number(gran)).fill(0);
      }
    });
  });

  const tableDataGran = {};
  let daCustomer = data.report.da_customer;
  let daSamawatt = data.report.da_samawatt;
  let idSamawatt = data.report.id_samawatt;
  let ibCustomer = data.report.ib_customer;
  let ibSamawatt = data.report.ib_samawatt;
  let totalCustomer = data.report.total_customer;
  let totalSamawatt = data.report.total_samawatt;
  let addedValue = data.report.added_value;

  let daTradesGran = {};
  let daPricesGran = {};
  let generationGran = {};
  let ibPriceUpGran = {};
  let ibPriceDownGran = {};
  let sWTotalGran = {};
  let idVolumesSamawattGran = {};
  let baseVolumesGran = {};

  uniqueDt.forEach((gran) => {
    let daVolumesSamawattGran;
    let daSamawattDetailGran;

    if (data.da_trades.length) {
      daTradesGran[gran] = oneDimVolGran(data.da_trades, daDt, gran, data.imbalance_prices.IBU.length * ibDt);
    } else {
      daTradesGran[gran] = Array(data.imbalance_prices.IBU.length * ibDt / Number(gran)).fill(0);
    }
    if (data.da_prices.length) {
      daPricesGran[gran] = oneDimDaPriceSamGran(
        data.da_trades,
        data.da_prices,
        daDt,
        gran,
        data.imbalance_prices.IBU.length * ibDt
      );
    } else {
      daPricesGran[gran] = Array(data.imbalance_prices.IBU.length * ibDt / Number(gran)).fill(0);
    }

    if (JSON.parse(data.report.da_volumes_samawatt).length) {
      daVolumesSamawattGran = oneDimVolGran(
        JSON.parse(data.report.da_volumes_samawatt),
        daDt,
        gran,
        data.imbalance_prices.IBU.length * ibDt
      );
    } else {
      daVolumesSamawattGran = Array(data.imbalance_prices.IBU.length * ibDt / Number(gran)).fill(0);
    }
    if (JSON.parse(data.report.da_samawatt_detail).length) {
      daSamawattDetailGran = oneDimVolGran(
        JSON.parse(data.report.da_samawatt_detail),
        daDt,
        gran,
        data.imbalance_prices.IBU.length * ibDt
      );
    } else {
      daSamawattDetailGran = Array(data.imbalance_prices.IBU.length * ibDt / Number(gran)).fill(0);
    }
    let daCustomerDetailGran = resampleArrToGran(
      JSON.parse(data.report.da_customer_detail),
      ibDt,
      gran
    );

    idVolumesSamawattGran[gran] =
      data.report.id_volumes_samawatt &&
      JSON.parse(data.report.id_volumes_samawatt).length
        ? oneDimVolGran(
            JSON.parse(data.report.id_volumes_samawatt), 
            idDt, 
            gran, 
            data.imbalance_prices.IBU.length * ibDt
          )
        : null;
    let idSamawattDetailGran =
      data.report.id_samawatt_detail &&
      JSON.parse(data.report.id_samawatt_detail).length
        ? oneDimVolGran(
            JSON.parse(data.report.id_samawatt_detail), 
            idDt, 
            gran, 
            data.imbalance_prices.IBU.length * ibDt
          )
        : null;

    let ibVolumesSamawattGran = resampleArrToGran(
      JSON.parse(data.report.ib_volumes_samawatt),
      ibDt,
      gran
    );
    let ibCustomerDetailGran = resampleArrToGran(
      JSON.parse(data.report.ib_customer_detail),
      ibDt,
      gran
    );
    let ibSamawattDetailGran = resampleArrToGran(
      JSON.parse(data.report.ib_samawatt_detail),
      ibDt,
      gran
    );
    ibPriceUpGran[gran] = resampleArrToGran(
      data.imbalance_prices.IBU,
      ibDt,
      gran
    );
    ibPriceDownGran[gran] = resampleArrToGran(
      data.imbalance_prices.IBD,
      ibDt,
      gran
    );
    baseVolumesGran[gran] = resampleArrToGran(data.base_volumes, ibDt, gran);
    generationGran[gran] = resampleArrToGran(data.generation, ibDt, gran);

    const tableData = [];
    const daibRatio = parseInt(
      data.generation.length / daTradesGran[gran].length
    );
    const swTotalArr = [];

    for (let i = 0; i < daTradesGran[gran].length; i++) {
      const hour = Math.floor(i * gran);
      const minute = (i * gran - Math.floor(i * gran)) * 60;

      const idVolumesSamawatt = idVolumesSamawattGran[gran]
        ? idVolumesSamawattGran[gran][i].toFixed(3)
        : 0.0.toFixed(3);
      const idSamawattDetail = idSamawattDetailGran
        ? idSamawattDetailGran[i].toFixed(2)
        : 0.0.toFixed(2);

      const swTotal =
        daSamawattDetailGran[i] +
        Number(idSamawattDetail) +
        ibSamawattDetailGran[i];
      swTotalArr.push(swTotal);

      let tableRow = [
        sprintf.sprintf('%02d:%02d', hour, minute),
        // Actual Production
        window.numberWithCommas(generationGran[gran][i].toFixed(3)),
        // Day-Ahead forecast
        window.numberWithCommas(baseVolumesGran[gran][i].toFixed(3)),
        // Day-Ahead trades
        window.numberWithCommas(daVolumesSamawattGran[i].toFixed(3)),
        // Intraday trades
        window.numberWithCommas(idVolumesSamawatt),
        // Imbalance
        window.numberWithCommas(ibVolumesSamawattGran[i].toFixed(3)),
        //Da prices
        window.numberWithCommas(daPricesGran[gran][i].toFixed(2)),
        // Up
        window.numberWithCommas(
          (ibPriceUpGran[gran][i] / parseFloat(daibRatio)).toFixed(2)
        ),
        // Down
        window.numberWithCommas(
          (ibPriceDownGran[gran][i] / parseFloat(daibRatio)).toFixed(2)
        ),
        // SW Day-ahead
        window.numberWithCommas(daSamawattDetailGran[i].toFixed(2)),
        // SW Intraday
        window.numberWithCommas(idSamawattDetail),
        // SW Imbalance
        window.numberWithCommas(ibSamawattDetailGran[i].toFixed(2)),
        // SW Total
        window.numberWithCommas(swTotal.toFixed(2)),
        // BA Day-ahead
        window.numberWithCommas(daCustomerDetailGran[i].toFixed(2)),
        // BA Imbalance
        window.numberWithCommas(ibCustomerDetailGran[i].toFixed(2)),
        // BA Total
        window.numberWithCommas(
          (daCustomerDetailGran[i] + ibCustomerDetailGran[i]).toFixed(2)
        ),
        // Added Value
        window.numberWithCommas(
          (
            daSamawattDetailGran[i] +
            Number(idSamawattDetail) +
            ibSamawattDetailGran[i] -
            (daCustomerDetailGran[i] + ibCustomerDetailGran[i])
          ).toFixed(2)
        ),
      ];

      tableData.push(tableRow);
    }

    tableData.push([
      'Total',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      // SW Day-ahead
      window.numberWithCommas(daSamawatt.toFixed(2)),
      // SW Intraday
      window.numberWithCommas(idSamawatt.toFixed(2)),
      // SW Imbalance
      window.numberWithCommas(ibSamawatt.toFixed(2)),
      // SW Total
      window.numberWithCommas(totalSamawatt.toFixed(2)),
      // BA Day-ahead
      window.numberWithCommas(daCustomer.toFixed(2)),
      // BA Imbalance
      window.numberWithCommas(ibCustomer.toFixed(2)),
      // BA Total
      window.numberWithCommas(totalCustomer.toFixed(2)),
      // Added Value
      window.numberWithCommas(addedValue.toFixed(2)),
    ]);
    tableDataGran[gran] = tableData;
    sWTotalGran[gran] = swTotalArr;
  });

  // Calculate DA open position, forecasting error and production sales, first for ibDt granularity
  const daOpenPositionGran = {};
  const prodSalesGran = {};
  const idPnlGran = {};
  daOpenPositionGran[ibDt] = [];
  prodSalesGran[ibDt] = [];
  idPnlGran[ibDt] = [];

  for (let i = 0; i < daTradesGran[ibDt].length; i++) {
    const daOpenPosVal =
      daTradesGran[ibDt][i] - generationGran[ibDt][i] > 0
        ? (daTradesGran[ibDt][i] - generationGran[ibDt][i]) *
          (daPricesGran[ibDt][i] - ibPriceDownGran[ibDt][i])
        : (daTradesGran[ibDt][i] - generationGran[ibDt][i]) *
          (daPricesGran[ibDt][i] - ibPriceUpGran[ibDt][i]);
    daOpenPositionGran[ibDt].push(daOpenPosVal);
    const prodSalesVal = generationGran[ibDt][i] * daPricesGran[ibDt][i];
    prodSalesGran[ibDt].push(prodSalesVal);
    idPnlGran[ibDt].push(
      idVolumesSamawattGran[ibDt] === null ||
        idVolumesSamawattGran[ibDt][i] === 0
        ? 0
        : sWTotalGran[ibDt][i] - (daOpenPosVal + prodSalesVal)
    );
  }
  uniqueDt.forEach((gran) => {
    if (gran === ibDt) return;
    daOpenPositionGran[gran] = resampleArrToGran(
      daOpenPositionGran[ibDt],
      ibDt,
      gran
    );
    prodSalesGran[gran] = resampleArrToGran(prodSalesGran[ibDt], ibDt, gran);
    idPnlGran[gran] = resampleArrToGran(idPnlGran?.[ibDt], ibDt, gran);
  });

  data.da_prices = oneDimDaPriceSam(data.da_trades, data.da_prices, daDt);
  data.da_trades = oneDimVol(data.da_trades, daDt, data.imbalance_prices.IBU.length * ibDt);
  if (!data.da_trades.length) {
    data.da_trades = Array(data.imbalance_prices.IBU.length * ibDt).fill(0);
    data.da_prices = Array(data.imbalance_prices.IBU.length * ibDt).fill(0);
  }
  let da_volumes_samawatt = oneDimVol(
    JSON.parse(data.report.da_volumes_samawatt),
    daDt,
    data.imbalance_prices.IBU.length * ibDt
  );
  if (!da_volumes_samawatt.length) {
    da_volumes_samawatt = Array(data.imbalance_prices.IBU.length * ibDt).fill(0);
  }
  let da_samawatt_detail = oneDimVol(
    JSON.parse(data.report.da_samawatt_detail),
    daDt,
    data.imbalance_prices.IBU.length * ibDt
  );
  if (!da_samawatt_detail.length) {
    da_samawatt_detail = Array(data.imbalance_prices.IBU.length * ibDt).fill(0);
  }

  let dt = moment.tz(data.parameters.date, data.parameters.timezone);

  for (let i = 0; i < data.da_trades.length; i++) {
    da_optimisation.push(data.da_trades[i]);
    da_optimisation.push(data.da_prices[i]);
  }

  var tableDetailsHead = ['Hour'];
  daTrades.forEach((el) => {
    tableDetailsHead.push('Day ahead');
  });

  data.id_trades = data.id_trades.length
    ? resampleIdTrades(data.id_trades, idDt)
    : [];
  if (data.id_trades !== null) {
    data.id_trades = data.id_trades.map((x) => ({
      // id_trade consist of [entry, contract, volume, profit]
      tradetime: moment(dt.tz('UTC'))
        .add(x[0], 'minutes')
        .format('YYYY-MM-DD HH:mm'),
      delivery: moment(dt.tz('UTC'))
        .add(x[1] * data.parameters.id_dt, 'hours')
        .format('YYYY-MM-DD HH:mm'),
      volume: x[2],
      profit: x[3],
    }));
  } else {
    data.id_trades = [];
  }
  var trade_times = data.id_trades
    .map((x) => x.tradetime)
    .filter((value, index, self) => self.indexOf(value) === index);

  for (let j = 0; j < trade_times.length; j++) {
    tableDetailsHead.push(
      moment
        .tz(trade_times[j], 'UTC')
        .tz('Europe/Berlin')
        .format('YYYY-MM-DD HH:mm')
    );
  }

  const year = data.dt_str.substring(0, 4);
  const month = data.dt_str.substring(4, 6);
  const day = data.dt_str.substring(6);
  dt = moment.tz(`${year}-${month}-${day}`, 'Europe/Paris');

  var dt1 = moment(dt);

  const report_rows = [];
  const id_report_rows = [];

  for (let i = 0; i < data.da_trades.length; i++) {
    var da_volume = da_optimisation[i * 2];
    var da_price = da_optimisation[i * 2 + 1];
    report_rows.push([da_volume, da_price]);
    id_report_rows.push([]);
    for (let j = 0; j < trade_times.length; j++) {
      let trade = data.id_trades.filter(
        (x) =>
          x.tradetime === trade_times[j] &&
          moment.utc(x.delivery).unix() === moment(dt1).add(i, 'hours').unix()
      );

      if (trade.length > 0) {
        let trade_price =
          trade[0].volume !== 0.0 ? trade[0].profit / trade[0].volume : 0.0;
        report_rows[i].push(trade[0].volume);
        report_rows[i].push(trade_price);
        id_report_rows[i].push(trade[0].volume);
        id_report_rows[i].push(trade_price);
      } else {
        report_rows[i].push('');
        report_rows[i].push('');
        id_report_rows[i].push('');
        id_report_rows[i].push('');
      }
    }
  }
  const idTradesData = {};

  for (let i = 0; i < data.da_trades.length; i++) {
    let hour = Math.floor(i);
    let minute = parseInt(i * 60) % 60;
    if (report_rows[i] !== undefined) {
      let idTableRow = [];

      // Fill idTradesData only if there are ID trades
      if (data.id_trades.length) {
        for (let j = 0; j < id_report_rows[i].length; j++) {
          if (j % 2 === 0) {
            if (id_report_rows[i][j].toString().length > 0) {
              idTableRow.push(id_report_rows[i][j].toFixed(3));
            } else {
              idTableRow.push('');
            }
          } else {
            if (id_report_rows[i][j].toString().length > 0) {
              idTableRow.push(id_report_rows[i][j].toFixed(2));
            } else {
              idTableRow.push('');
            }
          }
        }
        idTradesData[sprintf.sprintf('%02d:%02d', hour, minute)] = idTableRow;
      }
    }
  }

  //Collect DA data:volumes and prices in several granularity
  const tableDetailsDataGran = {};
  const auctionsData = {};
  Object.keys(data.granDaVolumes).forEach((stage) => {
    tableDetailsDataGran[stage] = {};
    auctionsData[stage] = {};
    Object.keys(data.granDaVolumes[stage]).forEach((gran) => {
      tableDetailsDataGran[stage][gran] = [];
      auctionsData[stage][gran] = {};
      for (let i = 0; i < data.granDaVolumes[stage][gran].length; i++) {
        var da_volume = data.granDaVolumes[stage][gran][i];
        var da_price = data.granDaPrices[stage][gran][i];
        let hour = Math.floor(i * gran);
        let minute = (i * gran - Math.floor(i * gran)) * 60;
        const hourMinute = sprintf.sprintf('%02d:%02d', hour, minute);
        const rowDa = [hourMinute, da_volume.toFixed(3), da_price.toFixed(2)];
        tableDetailsDataGran[stage][gran].push(rowDa);
        auctionsData[stage][gran][hourMinute] = [da_volume, da_price];
      }
    });
  });

  return {
    tableDetailsHead: tableDetailsHead,
    tableDetailsDataGran: tableDetailsDataGran,
    allData: data,
    idTradesData: idTradesData,
    auctionsData: auctionsData,
    tableDataGran: tableDataGran,
    daOpenPositionGran: daOpenPositionGran,
    prodSalesGran: prodSalesGran,
    idPnlGran: idPnlGran,
  };
}

export const security_fetch_params = {
  mode: 'cors',
  credentials: 'include',
};

export function buildAccountRequest(api_path) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  return [`https://accounts.sama.energy:${ACCOUNTS_API_PORT}${api_path}`, headers];
}

export const buildKUBERequest = (path) => {
  const headers = {
    'accept': 'application/json',
    'Content-Type': 'application/json',
  }

  return [`https://kube.sama.energy/gateway${path}`, headers];
}

// Function returns current API connection configuration
export function buildAPIRequest(api_path, mode) {
  const store = configureStore();
  const conn = store.getState().conn;

  const headers = (mode === 'urlencoded') ? {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  } : {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  return [conn.API_URL + api_path, headers, conn.target];
}

// Function returns default park
export function getDefaultPark() {
  const store = configureStore();
  if (typeof(store) === 'undefined') {
    return null;
  } else {
    const state = store.getState();
    if (typeof(state.conn.parks) === 'undefined') {
      return null;
    } else {
      if(state.conn.activePark) {
        return state.conn.activePark;
      }
      else if (state.conn.parks.length > 0) {
        return state.conn.parks[0];
      } else {
        return null;
      }
    }
  }
}


function receivedMarkets(json) {
    return {
        type: RECEIVED_MARKETS_DATA,
        markets: json,
        receivedAt: Date.now()
    }
}

function receivedMetricsData(json) {
    return {
        type: RECEIVED_METRICS_DATA,
        prices: json,
        receivedAt: Date.now()
    }
}

function receivedSubDaMetricsData(json) {
  return {
      type: RECEIVED_SUB_DA_METRICS_DATA,
      prices: json,
      receivedAt: Date.now()
  }
}

function receivedMetricsDataChunk(json) {
  return {
    type: RECEIVED_METRICS_DATA_CHUNK,
    prices: json,
    receivedAt: Date.now(),
  };
}

function receivedSubDaMetricsDataChunk(json) {
  return {
    type: RECEIVED_SUB_DA_METRICS_DATA_CHUNK,
    prices: json,
    receivedAt: Date.now(),
  };
}

function receivedMetricsDataAll(json, key) {
  return {
    type: RECEIVED_METRICS_DATA_ALL,
    prices: json,
    key,
    receivedAt: Date.now()
  }
}

function receivedSubDaMetricsDataAll(json, key) {
  return {
    type: RECEIVED_SUB_DA_METRICS_DATA_ALL,
    prices: json,
    key,
    receivedAt: Date.now()
  }
}

function receivedMetricsDataNotAll(json) {
  return {
    type: RECEIVED_METRICS_DATA_NOT_ALL,
    prices: json,
    receivedAt: Date.now()
  }
}

function receivedSubDaMetricsDataNotAll(json) {
  return {
    type: RECEIVED_SUB_DA_METRICS_DATA_NOT_ALL,
    prices: json,
    receivedAt: Date.now()
  }
}

function resetMetricsAllLoaded() {
  return {
    type: RESET_METRICS_DATA_ALL_LOADED,
    receivedAt: Date.now(),
  };
}

function stopMetricsLoaded(stop) {
  return {
    type: STOP_METRICS_DATA,
    stop: stop,
    receivedAt: Date.now(),
  };
}


function receivedJobs(json) {
    return {
        type: RECEIVED_JOBS_DATA,
        jobs: json,
        receivedAt: Date.now()
    }
}

function receivedNotifications(json) {
    return {
        type: RECEIVED_NOTIFICATIONS_DATA,
        notifications: json,
        receivedAt: Date.now()
    }
}

function receivedDashboard(json) {
    return {
        type: RECEIVED_DASHBOARD_DATA,
        dashboard: json,
        receivedAt: Date.now()
    }
}

function receivedDataDashboard(json, market) {
    return {
        type: RECEIVED_DATADASHBOARD_DATA,
        dashboard: json,
        market: market,
        receivedAt: Date.now()
    }
}

function receivedMarketsData(json) {
    return {
        type: RECEIVED_MARKETSDATA_DATA,
        dashboard: json,
        receivedAt: Date.now()
    }
}

function receivedOptimisationData(json) {
    return {
        type: RECEIVED_OPTIMISATION_DATA,
        optimisation: json,
        receivedAt: Date.now()
    }
}

function receivedRTraderData(json) {
    return {
        type: RECEIVED_RTRADER_DATA,
        rtrader: json,
        receivedAt: Date.now()
    }
}

function receivedRTraderMarketDepth(json) {
    return {
        type: RECEIVED_RTRADER_MARKETDEPTH,
        rtrader: json,
        receivedAt: Date.now()
    }
}

function receivedRTraderMarketTrades(json) {
    return {
        type: RECEIVED_RTRADER_MARKETTRADES,
        rtrader: json,
        receivedAt: Date.now()
    }
}

function receivedFundamentalsAudit(json) {
    return {
        type: RECEIVED_FUNDAMENTALS_AUDIT,
        fundamentals: json,
        receivedAt: Date.now()
    }
}


function receivedFundamentalsCurve(json) {
    return {
        type: RECEIVED_FUNDAMENTALS_CURVE,
        fundamentals_curve: json,
        receivedAt: Date.now()
    }
}

function receivedMarketScores(json) {
    return {
        type: RECEIVED_MARKET_SCORE,
        dashboard: json,
        receivedAt: Date.now()
    }
}


function receivedTradesDashboard(json) {
    return {
        type: RECEIVED_TRADESDASHBOARD_DATA,
        dashboard: json,
        receivedAt: Date.now()
    }
}


function receivedGeneration(json) {
    return {
        type: RECEIVED_GENERATION_DATA,
        generation: json,
        receivedAt: Date.now()
    }
}

function receivedGenerationChunk(json) {
  return {
    type: RECEIVED_GENERATION_DATA_CHUNK,
    generation: json,
    receivedAt: Date.now(),
  };
}


function receivedGenerationAll(json, key) {
  return {
    type: RECEIVED_GENERATION_DATA_ALL,
    generation: json,
    key,
    receivedAt: Date.now(),
  }
}


function resetGenerationAllLoaded() {
  return {
    type: RESET_GENERATION_DATA_ALL_LOADED,
    receivedAt: Date.now(),
  };
}


function loadingDashboard() {
    return {
        type: LOADING_DASHBOARD_DATA,
        receivedAt: Date.now()
    }
}

function loadingDataDashboard() {
    return {
        type: LOADING_DATADASHBOARD_DATA,
        receivedAt: Date.now()
    }
}

function loadingOptimisationData() {
    return {
        type: LOADING_OPTIMISATION_DATA,
        receivedAt: Date.now()
    }
}

function loadingRTraderData() {
    return {
        type: LOADING_RTRADER_DATA,
        receivedAt: Date.now()
    }
}

function loadingRTraderMarketDepth() {
    return {
        type: LOADING_RTRADER_MARKETDEPTH,
        receivedAt: Date.now()
    }
}

function loadingRTraderMarketTrades() {
    return {
        type: LOADING_RTRADER_MARKETTRADES,
        receivedAt: Date.now()
    }
}

function loadingFundamentalsAudit() {
    return {
        type: LOADING_FUNDAMENTALS_AUDIT,
        receivedAt: Date.now()
    }
}

function loadingFundamentalsCurve() {
    return {
        type: LOADING_FUNDAMENTALS_CURVE,
        receivedAt: Date.now()
    }
}

function loadingTradesDashboard() {
    return {
        type: LOADING_TRADESDASHBOARD_DATA,
        receivedAt: Date.now()
    }
}

function loadingJobs() {
    return {
        type: LOADING_JOBS_DATA,
        receivedAt: Date.now()
    }
}

function loadingNotifications() {
    return {
        type: LOADING_NOTIFICATIONS_DATA,
        receivedAt: Date.now()
    }
}

function loadingMarkets() {
    return {
        type: LOADING_MARKETS_DATA,
        receivedAt: Date.now()
    }
}

function loadingMetrics() {
    return {
        type: LOADING_METRICS_DATA,
        receivedAt: Date.now()
    }
}


function loadingChunksMetrics() {
    return {
        type: LOADING_CHUNKS_METRICS_DATA,
        receivedAt: Date.now()
    }
}


function loadingGeneration() {
    return {
        type: LOADING_GENERATION_DATA,
        receivedAt: Date.now()
    }
}


function loadingChunksGeneration() {
  return {
    type: LOADING_CHUNKS_GENERATION_DATA,
    receivedAt: Date.now(),
  }
}


function receivedReport(json) {
    return {
        type: RECEIVED_REPORT,
        report: json,
        receivedAt: Date.now()
    }
}

function loadingReport() {
    return {
        type: LOADING_REPORT,
        receivedAt: Date.now()
    }
}


function loadingPNLAnalysisData() {
  return {
    type: LOADING_PNL_ANALYSIS_DATA,
    receivedAt: Date.now(),
  };
}


function loadingParksData() {
  return {
    type: LOADING_PARKS_DATA,
    receivedAt: Date.now(),
  };
}


function preloadingPNLAnalysisData() {
  return {
    type: PRELOADING_PNL_ANALYSIS_DATA,
    receivedAt: Date.now(),
  };
}


function preloadedPNLAnalysisData(json) {
  return {
    type: PRELOADED_PNL_ANALYSIS_DATA,
    fullData: json,
    receivedAt: Date.now(),
  };
}


function receivedPNLAnalysisData(json) {
  return {
    type: RECEIVED_PNL_ANALYSIS_DATA,
    data: json,
    receivedAt: Date.now(),
  };
}

function receivedTopStrategiesData(json) {
  return {
    type: RECEIVED_TOP_STRATEGIES_DATA,
    data: json,
    receivedAt: Date.now(),
  };
}

function receivedParksPnlAnalysData(json, filter) {
  return {
    type: RECEIVED_PNL_ANALYS_PARKS_DATA,
    data: { parks: json, filter: filter },
    receivedAt: Date.now(),
  };
}

function receivedParksPnlPeriodData(json, filter) {
  return {
    type: RECEIVED_PNL_PERIOD_PARKS_DATA,
    data: { parks: json, filter: filter },
    receivedAt: Date.now(),
  };
}

function errorLoadingPNLAnalysisData(error) {
  return {
    type: ERROR_PRELOADING_PNL_ANALYSIS_DATA,
    error,
    receivedAt: Date.now(),
  };
}


// used by LoginForm.js (on login) and HeaderLinks.jsx (on logout)
export function set_logged_in(payload) {
    return { type: LOGGED_IN, payload };
}

export function setActiveAPI(payload) {
  return {
    type: CHANGE_CONNECTION,
    payload,
  }
}

export function set_uinfo(payload) {
    return { type: UINFO, payload };
}

export function set_targets(payload) {
  return { type: TARGETS, payload };
}

export function set_active_park(payload) {
    return { type: UPDATE_ACTIVE_PARK, payload };
}

export function set_current_page(payload) {
  return { type: UPDATE_CURRENT_PAGE, payload };
}

export function set_current_region(payload) {
  return { type: UPDATE_CURRENT_REGION, payload };
}

export function setCurrentAnalysMarket(payload) {
  return { type: UPDATE_CURRENT_ANALYS_MARKET, payload };
}

export function setVisibleRoutes(payload) {
  return { type: CHANGE_VISIBLE_ROUTES, payload };
}

export function updatePnlCurrentSettings(payload) {
  return { type: UPDATE_PNL_CURRENT_SETTINGS, payload };
}

export function updateBatteryTradingSettings(payload) {
  return { type: UPDATE_BATTERY_TRADING_SETTINGS, payload };
}

export function setLoginInstances(payload) {
  return { type: UPDATE_LOGIN_INSTANCES, payload };
}

export function set_optimisation_type(payload) {
  return { type: UPDATE_OPTIMISATION_TYPE, payload}
}

export function set_market_filter(payload) {
  return { type: UPDATE_MARKET_FILTER, payload };
}

export function set_variable_filter(payload) {
  return { type: UPDATE_VARIABLE_FILTER, payload };
}

export function set_auditor_filter(payload) {
  return { type: UPDATE_AUDITOR_FILTER, payload };
}

export function set_source_filter(payload) {
  return { type: UPDATE_SOURCE_FILTER, payload };
}

export function set_audit_summary_loading(payload) {
  return { type: LOADING_AUDIT_SUMMARY, payload };
} 

export function set_importance_filter(payload) {
  return { type: UPDATE_IMPORTANCE_FILTER, payload };
}

export function set_audit_date_from(payload) {
  return { type: UPDATE_DATE_FROM, payload };
}

export function set_audit_date_to(payload) {
  return { type: UPDATE_DATE_TO, payload };
}

export function set_audit_search(payload) {
  return { type: UPDATE_AUDIT_SEARCH, payload };
}

export function set_audit_alerts_type(payload) {
  return { type: UPDATE_AUDIT_ALERTS_TYPE, payload };
}

export function set_audit_alerts_status(payload) {
  return { type: UPDATE_AUDIT_ALERTS_STATUS, payload };
}

export function set_show_optimisation_non_active(payload) {
  return { type: SHOW_OPTIMISATION_NON_ACTIVE, payload };
}

export function set_pnl_interval_currency_type(payload) {
  return { type: UPDATE_PNL_INTERVAL_CURRENCY_TYPE, payload };
}

function download_plreport(dispatch, parkId, dt_str, isSkipIntraday, forceReCalculate, withoutCache) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    if (forceReCalculate !== true) {
        forceReCalculate = false;
    }
    var path = '/api/windparks/' + parkId + '/pl_detail/' + dt_str + '?forceReCalculate=' + (forceReCalculate ? '1' : '0') + '&noID=' + (isSkipIntraday ? '1' : '0') + '&cache=' + (withoutCache);
    const [url, headers] = buildAPIRequest(path);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();
                    let receiveData = {};
                    if (data.base_volumes === null) {
                        alertify.error('No P&L report for ' + dt_str.substring(0, 4) + '-' + dt_str.substring(4, 6) + '-' + dt_str.substring(6, 8), 'error', 5)
                        receiveData = {
                          tableData: [],
                          tableDetailsData: [],
                          tableDetailsHead: []
                        }
                        dispatch(receivedReport(receiveData));
                    } else {
                        data.dt_str = dt_str;
                        var res = null;

                        res = buildRows(data);

                        res.dt_str = dt_str;
                        res.updated_at = moment().unix();
                        res.hourly = data.report;
                        res.parameters = data.parameters;
                        res.isSkipIntraday = isSkipIntraday;
                        receiveData = res;
                        dispatch(receivedReport(receiveData));
                    }
                    localForage.setItem(`optimalwind_plreport_${parkId}_${dt_str}${isSkipIntraday ? '_1' : '_0'}_${apiLabel}`, receiveData, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


export function get_plreport_data(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        var dt_str = payload.date.replaceAll("-", "");
        dispatch(loadingReport());
        localForage.getItem(`optimalwind_plreport_${payload.parkId}_${dt_str}${payload.isSkipIntraday ? '_1' : '_0'}_${apiLabel}`, function (err, value) {
                if (value) {
                    if (value.updated_at < moment().unix() - 3600) {
                        download_plreport(dispatch, payload.parkId, dt_str, payload.isSkipIntraday, payload.forceReCalculate, payload.withoutCache);
                    }
                    dispatch(receivedReport(value));
                } else {
                    download_plreport(dispatch, payload.parkId, dt_str, payload.isSkipIntraday, payload.forceReCalculate, payload.withoutCache);
                }
            });
    }
}


async function downloadPNLAnalysisData(
  dispatch, period, periodKey, key, parks, dateFrom, dateTo, preloading = false
) {
  // Make request for to PNL interval API for each park
  async function downloadPNLIntervalData(park, from, to) {
    const sections = [
      'added_value', 'da_volumes_samawatt', 'ib_samawatt_detail', 'id_volumes_samawatt',
      'ib_volumes_samawatt', 'ib_volumes_customer', 'pl_samawatt', 'pl_customer','extra_data'
    ];
    const path = `/api/windparks/${park.id}/pl_interval/${from}/${to}`
    + `?sections=${sections.join(',')}&details=1`;
    const [url, headers] = buildAPIRequest(path);
    const response = await fetch(url, { method: 'GET', headers, ...security_fetch_params });
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      const msg = `An error has occurred: ${response.status}`;
      alertify.error(msg, 5);
      console.log(msg);
      return;
    }
    const data = await response.json();
    if (data && data.error) {
      alertify.error("Response error");
      console.log(data.error);
      return
    }
    if (!data?.data?.length) {
      const msg = `No data for ${park.name}, since ${dateFrom} till ${dateTo}`;
      alertify.error(msg, 5);
      console.log(msg);
      return;
    }

    return {
      id: park.id,
      name: park.name,
      data: data.data,
    };
  }

  // Make request and save PNL Data
  async function storePNLIntervalData(park, data) {
    data.updated_at = moment().unix();
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    // Save data for future usage
    await localForage.setItem(`${key}_${park.id}_${apiLabel}`, data, (err) => {
      (err) && console.log(err);
    });
    return data;
  }

  // Request or restore from cache PNL interval data for each park
  async function downloadOrRestorePNLIntervalData(park) {
    try {
      const store = configureStore();
      const apiLabel = store.getState().conn.label;
      const cachedData = await localForage.getItem(`${key}_${park.id}_${apiLabel}`);
      // If data was previously loaded in the last hour (in milliseconds)
      const INTERVAL = 3600000;
      if (!cachedData || (cachedData.updated_at < moment().unix() - INTERVAL)) {
        const data = await downloadPNLIntervalData(park, dateFrom, dateTo);
        await storePNLIntervalData(park, data);
        return data;
      }

      // If there's preloaded data exists, we just need to slice
      // the needed part. So, prepare values for slicing.
      let slicedData = { ...cachedData };
      // Get length of data
      const len = cachedData.data.length;
      // Get min and max stored dates
      const dates = cachedData.data.map((v) => v.date).sort();
      const minStoredDate = moment(dates[0]);
      const maxStoredDate = moment(dates[len - 1]);

      // Match that needed dates are in stored range
      if (minStoredDate.isSameOrBefore(dateFrom)
        && maxStoredDate.isSameOrAfter(dateTo)) {
        slicedData.data = slicedData.data.filter((v) => (
          moment(v.date).isBetween(dateFrom, dateTo, undefined, '[]')
        ));
        const sortSlicedData = { ...slicedData };
        sortSlicedData.data = slicedData.data.sort(
          (a, b) => moment(a.date, 'YYYY-MM-DD') > moment(b.date, 'YYYY-MM-DD') ? 1 : -1
        );
        return sortSlicedData;
      } else {
        // Download left part of data if interval isn't full
        if (minStoredDate.isAfter(dateFrom)) {
          const data = await downloadPNLIntervalData(
            park,
            dateFrom,
            minStoredDate.clone().add(-1, 'days').format('YYYYMMDD'),
          );
          // Extend previously downloaded data
          slicedData.data = [ ...slicedData.data, ...data.data];
        }
        // Download right part of data if interval isn't full
        if (maxStoredDate.isBefore(dateTo)) {
          const data = await downloadPNLIntervalData(
            park,
            maxStoredDate.clone().add(1, 'days').format('YYYYMMDD'),
            dateTo,
          );
          // Extend previously downloaded data
          slicedData.data = [ ...slicedData.data, ...data.data];
        }
        // Compare data w/ stored period
        if (slicedData.data.length !== cachedData.data.length) {
          // Store updated data
          await storePNLIntervalData(park, slicedData);
          // Slice selected period
          slicedData.data = slicedData.data.filter((v) => (
            moment(v.date).isBetween(dateFrom, dateTo, undefined, '[]')
          ));
          const sortSlicedData = { ...slicedData };
          sortSlicedData.data = slicedData.data.sort(
            (a, b) =>
              moment(a.date, 'YYYY-MM-DD') > moment(b.date, 'YYYY-MM-DD') ? 1 : -1
          );
          return sortSlicedData;
        } else {
          // If requested data is out of a stored range
          const data = await downloadPNLIntervalData(park, dateFrom, dateTo);
          await storePNLIntervalData(park, data);
          return data;
        }
      }
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.log(err);
      }
    }
  }
  let stringifiedTotalData = null;
  let storedTotalData = null;
  try{
    stringifiedTotalData = periodKey ? await localForage.getItem(periodKey) : null;
    storedTotalData = await JSON.parse(stringifiedTotalData);
  } catch (err) {
    console.log(err);
  }

  const ONE_DAY_INTERVAL = 86400;
  const UNIX_YEAR = 31556926;
  
  if (!storedTotalData || (storedTotalData.updated_at < moment().unix() - ONE_DAY_INTERVAL)) {
    const totalData = await Promise.all(
      parks.map((park) => downloadOrRestorePNLIntervalData(park))
    ).catch((err) => {
      console.log(err.message);
      alertify.error(err.message, 5);
      dispatch(errorLoadingPNLAnalysisData(err.message));
    });
    if (totalData.length) {
      totalData.updated_at = moment().unix();
      
      // If period is less than 1 year save data to storage
      if (period && (period < UNIX_YEAR)) {
        const stringifiedTotalData = JSON.stringify({data: totalData, updated_at: totalData.updated_at})
        try {
          await localForage.setItem(periodKey, stringifiedTotalData);
        } catch (err) {
          console.log(err);
        }
      }
      // Fire preloading case, don't send the data. It should be only stored
      preloading
        ? dispatch(preloadedPNLAnalysisData(totalData))
        : key === 'pnl_analysis' ? dispatch(receivedPNLAnalysisData(totalData)) : dispatch(receivedTopStrategiesData(totalData));
    }

    return totalData;
  }
  
  preloading
    ? dispatch(preloadedPNLAnalysisData(storedTotalData.data))
    : key === 'pnl_analysis' ? dispatch(receivedPNLAnalysisData(storedTotalData.data)) : dispatch(receivedTopStrategiesData(storedTotalData.data));

  return storedTotalData.data;
}


export function getPNLAnalysisData(payload) {
  return (dispatch) => {
    const { parks, dateFrom, dateTo, preloading, key, periodKey, period } = payload;
    preloading
      ? dispatch(preloadingPNLAnalysisData())
      : dispatch(loadingPNLAnalysisData());

    downloadPNLAnalysisData(
      dispatch, period, periodKey, key, parks, dateFrom, dateTo, preloading
    );
  }
}


function download_dashboard(dispatch, park_id) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const park = getDefaultPark();
    if (park_id === null || park_id === undefined) {
        park_id = park.id
    }
    const [url, headers] = buildAPIRequest(`/api/reports/dashboard?park_id=${park_id}`);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();
                    if (data.error === undefined) {
                        localForage.setItem(`dashboard_${park_id}_${apiLabel}`, data, function (err) {
                            if (err) {
                                console.log(err);
                            }
                        });
                        dispatch(receivedDashboard(data));
                    }
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


function download_datadashboard(dispatch, market) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/reports/datadashboard?market=' + market);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();
                    localForage.setItem(`datadashboard_${market}_${apiLabel}`, data, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedDataDashboard(data, market));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


function download_marketscores(dispatch, importance, country, source) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/reports/marketscore/' + importance + '/' + country + '/' + source);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();
                    localForage.setItem(`marketscores_${importance}_${country}_${source}_${apiLabel}`, data , function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedMarketScores(data));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


function download_marketsdata(dispatch) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/reports/marketsdata');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();
                    localForage.setItem(`marketsdata_${apiLabel}`, data, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedMarketsData(data));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}

let download_optimisation_data = async (dispatch, id) => {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/reports/optimisation/' + id);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then((basedata) => {
            if (basedata && basedata.error) {
              console.log(basedata.error);
              return
            }
            basedata.updated_at = moment().unix();
            localForage.setItem(
              `optimisation_result_${id}_${apiLabel}`,
              basedata,
              function (err) {
                if (err) {
                  console.log(err);
                }
              }
            );
            dispatch(receivedOptimisationData(basedata));
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
};

function download_rtrader_orders(dispatch, wpark_id, delivery) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    var deliveryts = 0;
    if (typeof (delivery) == "string") {
        deliveryts = delivery;
    } else {
        deliveryts = moment.tz(delivery * 1000, 'UTC').format('YYYY-MM-DD HH:mm');
    }

    let headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    var url = 'https://tradingwss.tradingintegralsolutions.com/api/login';
    fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ "login": "user", "password": "5TYe5Eg5" }),
    })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    var token = data.token;
                    var newdata = {};
                    url = 'https://cors-anywhere.herokuapp.com/https://tradingwss.tradingintegralsolutions.com/api/find_orders?dlvryAreaId=10YFR-RTE------C&delivery_start=' + deliveryts;
                    headers.set('Authorization', token);
                    fetch(url, {
                        method: 'GET',
                        headers: headers,
                    })
                        .then((response) => {
                            if (response.ok) {
                                response.json().then(data => {
                                    if (data && data.error) {
                                      alertify.error("Response error");
                                      console.log(data.error);
                                      return
                                    }
                                    newdata.orders = data.data;
                                    url = 'https://cors-anywhere.herokuapp.com/https://tradingwss.tradingintegralsolutions.com/api/find_trades?delivery_start=' + deliveryts;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    })
                                        .then((response) => {
                                            if (response.ok) {
                                                response.json().then(data => {
                                                    if (data && data.error) {
                                                      alertify.error("Response error");
                                                      console.log(data.error);
                                                      return
                                                    }
                                                    newdata.trades = data.data;
                                                    newdata.updated_at = moment().unix();
                                                    localForage.setItem(`rtrader_orders_${wpark_id}_${deliveryts}_${apiLabel}`, newdata, function (err) {
                                                        if (err) {
                                                            console.log(err);
                                                        }
                                                    })
                                                    dispatch(receivedRTraderData(newdata));
                                                });
                                            }
                                        })
                                });
                            }
                        });
                })
            }
        })
        .catch((error) => { console.log(error) })
}


function download_rtrader_marketdepth(dispatch, delivery, country_iso3166a2) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    var deliveryts = 0;
    if (typeof (delivery) == "string") {
        deliveryts = moment.tz(delivery, 'UTC').unix();
    } else {
        deliveryts = delivery;
    }

    if (country_iso3166a2 === undefined) {
        country_iso3166a2 = 'FR'
    }

    const [url, headers] = buildAPIRequest('/api/reports/rtrader/market_orders?delivery=' + deliveryts + '&country_iso3166a2=' + country_iso3166a2);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    var newdata = {};
                    newdata.data = data;
                    newdata.delivery = deliveryts;
                    newdata.updated_at = moment().unix();
                    localForage.setItem(`rtrader_marketdepth_${country_iso3166a2}_${deliveryts}_${apiLabel}`, newdata, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedRTraderMarketDepth(newdata));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


function download_rtrader_markettrades(
  dispatch, delivery, country_iso3166a2, pageSize = DEFAULT_PAGE_SIZE,
) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    var deliveryts = 0;
    var country = 'FR';
    if (typeof (delivery) == "string") {
        deliveryts = moment.tz(delivery, 'UTC').unix();
    } else {
        deliveryts = delivery;
    }
    if (!deliveryts) return;

    if (country_iso3166a2) {
        country = country_iso3166a2
    }

    var ts_min = deliveryts;
    var ts_max = deliveryts + 60;

    const [url, headers] = buildAPIRequest('/api/reports/rtrader/trades?ts_min=' + ts_min + '&ts_max=' + ts_max + '&country_iso3166a2=' + country + '&agg=false&trade_depth=60');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    var newdata = {};
                    if (data && data.error) {
                        return;
                    }
                    newdata.data = data.slice(0, pageSize);
                    newdata.delivery = deliveryts;
                    newdata.updated_at = moment().unix();
                    localForage.setItem(`rtrader_markettrades_${country_iso3166a2}_${deliveryts}_${apiLabel}`, newdata, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedRTraderMarketTrades(newdata));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}

function download_fundamentals_curve(
  dispatch, variable, market, dateFrom, issue_ts,
) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const days = moment.tz(issue_ts * 1000, 'UTC')
      .diff(dateFrom, 'days');
    const [url, headers] = buildAPIRequest('/api/reports/fundamentals_curve/' + btoa(variable) + '/' + market + '/' + issue_ts + `?history_days=${days}`);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    var newdata = {};
                    newdata.data = data;
                    newdata.updated_at = moment().unix();
                    localForage.setItem(`fundamentals_curve_${variable}_${market}_${issue_ts}_${apiLabel}`, newdata, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedFundamentalsCurve(newdata));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}

function download_fundamentals_audit(dispatch) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/reports/fundamentals');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    var newdata = {};
                    newdata.data = data;
                    newdata.updated_at = moment().unix();
                    localForage.setItem(`fundamentals_audit_${apiLabel}`, newdata, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedFundamentalsAudit(newdata));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


function download_tradesdashboard(dispatch) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/reports/tradesdashboard');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();
                    localForage.setItem(`tradesdashboard_${apiLabel}`, data, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedTradesDashboard(data));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


export function get_dashboard_data(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingDashboard());
        var park = null;
        if (payload !== undefined) {
            park = payload.park_id
        }
        localForage.getItem(`dashboard_${park}_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 28800) {
                    download_dashboard(dispatch, park);
                }
                dispatch(receivedDashboard(value));
            }
            else {
                download_dashboard(dispatch, park);
            }
        });
    }
}


export function get_marketsdata_data(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingDataDashboard());
        localForage.getItem(`marketsdata_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 10) {
                    download_marketsdata(dispatch);
                }
                dispatch(receivedMarketsData(value));
            }
            else {
                download_marketsdata(dispatch);
            }
        });
    }
}

export function get_rtrader_data(payload) {
    return dispatch => {
        var value = { 'delivery_hours': [] }
        dispatch(receivedRTraderData(value));
    }
}


export function get_rtrader_orders(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        var deliveryts = "";
        if (typeof (payload.delivery) == "string") {
            deliveryts = payload.delivery;
        } else {
            deliveryts = moment.tz(payload.delivery * 1000, 'UTC').format('YYYY-MM-DD HH:mm');
        }
        if (!deliveryts) return;
        dispatch(loadingRTraderData());

        localForage.getItem(`rtrader_orders_${payload.wpark_id}_${deliveryts}_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 10) {
                    download_rtrader_orders(dispatch, payload.wpark_id, deliveryts);
                }
                dispatch(receivedRTraderData(value));
            }
            else {
                download_rtrader_orders(dispatch, payload.wpark_id, deliveryts);
            }
        });
    }
}

export function get_rtrader_marketdepth(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        var deliveryts = 0;
        var country_iso3166a2 = 'FR';
        if (typeof (payload.delivery) == "string") {
            deliveryts = moment.tz(payload.delivery, 'UTC').unix();
        } else {
            deliveryts = payload.delivery;
        }
        if (!deliveryts) return;
        dispatch(loadingRTraderMarketDepth());

        if (payload.country_iso3166a2) {
            country_iso3166a2 = payload.country_iso3166a2
        }

        localForage.getItem(`rtrader_marketdepth_${country_iso3166a2}_${deliveryts}_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 10) {
                    download_rtrader_marketdepth(dispatch, deliveryts, country_iso3166a2);
                }
                dispatch(receivedRTraderMarketDepth(value));
            }
            else {
                download_rtrader_marketdepth(dispatch, deliveryts, country_iso3166a2);
            }
        });
    }
}

export function get_fundamentals_curve(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingFundamentalsCurve());

        let issue_ts = 0;
        const { variable, country, dateFrom } = payload;
        if (typeof payload.issue_ts === "string") {
            issue_ts = moment.tz(payload.issue_ts, 'UTC').unix();
        } else {
            issue_ts = payload.issue_ts;
        }
        localForage.getItem(`fundamentals_curve_${variable}_${country}_${issue_ts}_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 10) {
                    download_fundamentals_curve(
                      dispatch, variable, country, dateFrom, issue_ts,
                    );
                }
                dispatch(receivedFundamentalsCurve(value));
            }
            else {
                download_fundamentals_curve(
                  dispatch, variable, country, dateFrom, issue_ts,
                );
            }
        });
    }
}

export function get_fundamentals_audit(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingFundamentalsAudit());
        localForage.getItem(`fundamentals_audit_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 10) {
                    download_fundamentals_audit(dispatch);
                }
                dispatch(receivedFundamentalsAudit(value));
            }
            else {
                download_fundamentals_audit(dispatch);
            }
        });
    }
}

// To be adopted for a new endpoint
export function get_rtrader_markettrades(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        var deliveryts = 0;
        if (typeof (payload.delivery) == "string") {
            deliveryts = moment.tz(payload.delivery, 'UTC').unix();
        } else {
            deliveryts = payload.delivery;
        }
        if (!deliveryts) return;
        dispatch(loadingRTraderMarketTrades());

        var country_iso3166a2 = 'FR'; // Set default country
        if (payload.country_iso3166a2) {
            country_iso3166a2 = payload.country_iso3166a2;
        }
        let pageSize = DEFAULT_PAGE_SIZE;
        if (payload.pageSize) {
            pageSize = payload.pageSize;
        }
        localForage.getItem(
            `rtrader_markettrades_${country_iso3166a2}_${deliveryts}_${apiLabel}`,
            (err, value) => {
                // If cached data is enough as required with new pageSize
                if (value && value?.data?.length >= pageSize) {
                    if (value.updated_at < moment().unix() - 10) {
                        download_rtrader_markettrades(
                            dispatch,
                            deliveryts,
                            country_iso3166a2,
                            pageSize,
                        );
                    }
                    dispatch(receivedRTraderMarketTrades(value));
                } else {
                    download_rtrader_markettrades(
                        dispatch,
                        deliveryts,
                        country_iso3166a2,
                        pageSize,
                    );
                }
            },
        );
    }
}


export function get_optimisation_data(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingOptimisationData());
        localForage.getItem(`optimisation_result_${payload.id}_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 10) {
                    download_optimisation_data(dispatch, payload.id);
                }
                dispatch(receivedOptimisationData(value));
            }
            else {
                download_optimisation_data(dispatch, payload.id);
            }
        });
    }
}


export function get_market_scores(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        localForage.getItem(`marketscores_${payload.importance}_${payload.country}_${payload.source}_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 100) {
                    download_marketscores(dispatch, payload.importance, payload.country, payload.source);
                }
                dispatch(receivedMarketScores(value));
            }
            else {
                download_marketscores(dispatch, payload.importance, payload.country, payload.source);
            }
        });
    }
}


export function get_datadashboard_data(market) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingDataDashboard());
        localForage.getItem(`datadashboard_${market}_${apiLabel}`, function (err, value) {
            if (value) {
                if (value.updated_at < moment().unix() - 100) {
                    download_datadashboard(dispatch, market);
                }
                dispatch(receivedDataDashboard(value, market));
            }
            else {
                download_datadashboard(dispatch, market);
            }
        });
    }
}


export function get_tradesdashboard_data(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingTradesDashboard());
        localForage.getItem(`tradesdashboard_${apiLabel}`, function (err, value) {
            if (value && 'portfolios' in value) {
                if (value.updated_at < moment().unix() - 100) {
                    download_tradesdashboard(dispatch);
                }
                dispatch(receivedTradesDashboard(value));
            }
            else {
                download_tradesdashboard(dispatch);
            }
        });
    }
}


async function download_generation(
  dispatch, windpark, dateFrom, dateTo, isDownloadingByChunks = false,
) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    let from = dateFrom;
    let to = dateTo;
    const createKey = (from) => `generation_data_${windpark}_${from}_${to}_${apiLabel}`;
    const key = isDownloadingByChunks
      ? createKey('all') : createKey(from);

    async function loadData(from, to) {
      const [url, headers] = buildAPIRequest(
        `/api/windparks/generation/${windpark}?start=${from}&end=${to}`,
      );

      const response = await fetch(url, { method: 'GET', headers, ...security_fetch_params });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
        return;
      }
      return await response.json();
    }

    let allDataLoaded = false;
    while (!allDataLoaded) {
      let generation = await loadData(from, to);
      if (generation && generation.error) {
        console.log(generation.error);
        return
      }

      const [url, headers] = buildAPIRequest(
        `/api/windparks/report_productive/${windpark}`
      );
      const response = await fetch(url, { method: 'GET', headers, ...security_fetch_params });
      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        console.log(`An error has occurred: ${response.status}`);
        return;
      }
      const data = await response.json();
      if (data && data.error) {
        alertify.error("Response error");
        console.log(data.error);
        return
      }
      generation.updated_at = moment().unix();
      generation.report_productive = data;
      if (!isDownloadingByChunks) {
        localForage.setItem(
          key,
          generation,
          (err) => {
            if (err) {
              console.log(err);
            }
        });
        allDataLoaded = true;
        dispatch(receivedGeneration(generation));
      } else {
        // Exit from continuously requesting API.
        // Even if there's no data in DB, API returns timestamps with zero
        // values. So, try to filter empty data.
        const indexes = generation?.data?.forecast?.map(
          (x, i) => (
            (x[1] !== 0
              || generation?.data.latest_forecast[i][1] !== 0
              || generation?.data.power[i][1] !== 0
            ) && i
          ),
        );
        const filteredData = {
          data: {
            forecast: generation?.data.forecast.filter(
              (_, i) => indexes.includes(i),
            ),
            latest_forecast: generation?.data.latest_forecast.filter(
              (_, i) => indexes.includes(i),
            ),
            power: generation?.data.power.filter(
              (_, i) => indexes.includes(i),
            ),
          },
          report_productive: generation?.report_productive,
        };
        // If there's some not null data
        if (filteredData.data.forecast.length > 0) {
          // Change from/to date and continue
          to = from;
          from = moment(to).add(-BULK_LOAD_PERIOD, 'months')
            .format('YYYYMMDD');
          dispatch(receivedGenerationChunk(filteredData));
        } else {
          allDataLoaded = true;
          dispatch(receivedGenerationAll(filteredData, key));
        }
      }
    }
}


export function get_generation_data(payload) {
  return dispatch => {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const { windpark, dateFrom, dateTo } = payload;
    const isDownloadingByChunks = payload.isDownloadingByChunks ?? false;
    const key = isDownloadingByChunks
      ? `generation_data_${windpark}_all_${dateTo}_${apiLabel}`
      : `generation_data_${windpark}_${dateFrom}_${dateTo}_${apiLabel}`;

    dispatch(loadingGeneration());
    isDownloadingByChunks && dispatch(loadingChunksGeneration());

    localForage.getItem(
      key,
      (err, value) => {
        if (value) {
          isDownloadingByChunks
            ? dispatch(receivedGenerationAll(value, key))
            : dispatch(receivedGeneration(value));
          if (value.updated_at < moment().unix() - 1000) {
            download_generation(
              dispatch, windpark, dateFrom, dateTo, isDownloadingByChunks,
            );
          }
        } else {
          download_generation(
            dispatch, windpark, dateFrom, dateTo, isDownloadingByChunks,
          );
        }
    });
  }
}


export function resetGenerationDataAllLoaded() {
  return dispatch => {
    dispatch(resetGenerationAllLoaded());
  };
}


function download_markets(dispatch) {
    const [url, headers] = buildKUBERequest('/api/markets');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();
                    localForage.setItem(`markets`, data, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedMarkets(data));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}


async function download_metrics(
  dispatch, market, variable, date_from, date_to, subDA, granularity,
  isDownloadingByChunks = false,
) {
  let from = date_from;
  let to = date_to;
  const store = configureStore();
  const apiLabel = store.getState().conn.label;

  async function loadData(dateFrom, dateTo, dt, key) {
    const [url, headers] = buildAPIRequest(
      `/api/reports/metrics/${market}/${variable}/${dateFrom}/${dateTo}`
      + `?subDA=${subDA}&dt=${dt}`);

    const response = await fetch(url, { method: 'GET', headers, ...security_fetch_params });
    const data = await response.json();
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
    } else if (data && data.error) {
      alertify.error("Response error")
      console.log(data.error);
      return [];
    } else {
      data.updated_at = moment().unix();
      localForage.setItem(key, data, (err) => {
        if (err) {
          console.log(err);
        }
      });
    }
    return data;
  }

  async function downloadMetrics(dt) {
    
    let allDataLoaded = false;
    while (!allDataLoaded) {
      const state = store.getState();
      let key = `metricsdata_${market}_${variable}_${from}_${to}_${subDA}_${dt}_${apiLabel}`
      let data = null;

      // If user start loading full data and stop the process, then
      // next time data that loaded before should be used.
      const value = await localForage.getItem(key);
      if (value) {
        if (value.updated_at < moment().unix() - 3600) {
          data = await loadData(from, to, dt, key);   
        }
        else {
          data = value;
        }
      } 
      else {
        data = await loadData(from, to, dt, key);
      }

      if (!isDownloadingByChunks) {
        allDataLoaded = true;
        subDA === '1' ? dispatch(receivedSubDaMetricsData(data)) : dispatch(receivedMetricsData(data));
      } else {
        // Exit from continuously requesting API.
        // Even if there's no data in DB, API returns timestamps with null
        // values. So, try to find not nullish data.
        const filteredData = data.filter(
          (x) => Object.entries(x)
            .filter((v) => v[0] !== 'time')
            .some((v) => v[1] !== null)
        );
        // If there's some not null data
        if (filteredData.length > 0) {
          subDA === '1' ? dispatch(receivedSubDaMetricsDataChunk(filteredData)) : dispatch(receivedMetricsDataChunk(filteredData));
          // Change from/to date and continue
          to = from;
          from = moment(to).add(-BULK_LOAD_PERIOD, 'months')
            .format('YYYYMMDD');
        } else {
          allDataLoaded = true;
          key = `metricsdata_${market}_${variable}_all_${to}_${subDA}_${apiLabel}`;
          subDA === '1' ? dispatch(receivedSubDaMetricsDataAll(filteredData, key)) : dispatch(receivedMetricsDataAll(filteredData, key));
        }
        // If user stop loading data, then stop requesting API
        if(state.metrics.stopLoading) {
          allDataLoaded = true;
          subDA === '1' ? dispatch(receivedSubDaMetricsDataNotAll(filteredData)) : dispatch(receivedMetricsDataNotAll(filteredData));
        }
      }
    }
  }

  // Request period values for countries
  // 1. Check for saved answer
  const key = `imbalance_dt_${apiLabel}`;
  localForage.getItem(key, (err, value) => {
    if (value) {
      downloadMetrics(granularity);
    } else {
      // 2. Otherwise, request period values
      const [url, headers] = buildAPIRequest(
        '/api/markets/parameters/IMBALANCE_DT',
      );
      fetch(url, {method: 'GET', headers, ...security_fetch_params}).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // 3. Save response to omit requesting again
            localForage.setItem(key, data.data, (err) => {
              if (err) {
                console.log(err);
              }
            });
            // 4. When we get dt value, request for metrics
            downloadMetrics(granularity);
          });
        } else if (response.status === 401) {
          logout();
          return;
        }
      }).catch((error) => { console.log(error); });
    }
    return true;
  });
}


export function get_metrics_data(payload) {
  const store = configureStore();
  const apiLabel = store.getState().conn.label;
  return dispatch => {
    const {
      market, variable, date_from, date_to, subDA, granularity,
    } = payload;
    const isDownloadingByChunks = payload.isDownloadingByChunks ?? false;
    const key = isDownloadingByChunks
      ? `metricsdata_${market}_${variable}_all_${date_to}_${subDA}_${granularity}_${apiLabel}`
      : `metricsdata_${market}_${variable}_${date_from}_${date_to}_${subDA}_${granularity}_${apiLabel}`;

    dispatch(loadingMetrics());
    isDownloadingByChunks && dispatch(loadingChunksMetrics());

    localForage.getItem(key, function (err, value) {
      if (value) {
        if (value.updated_at < moment().unix() - 3600) {
          download_metrics(
            dispatch, market, variable, date_from, date_to, subDA, granularity,
            isDownloadingByChunks,
          );
        } else {
          if (subDA === '1') {
            isDownloadingByChunks
              ? dispatch(receivedSubDaMetricsDataAll(value, key))
              : dispatch(receivedSubDaMetricsData(value));
          } else {
            isDownloadingByChunks
              ? dispatch(receivedMetricsDataAll(value, key))
              : dispatch(receivedMetricsData(value));
          }
        }
      } else {
        download_metrics(
          dispatch, market, variable, date_from, date_to, subDA, granularity,
          isDownloadingByChunks,
        );
      }
    });
  }
}


export function resetMetricsDataAllLoaded() {
  return dispatch => {
    dispatch(resetMetricsAllLoaded());
  };
}


export function stopMetricsDataLoaded(payload) {
  return dispatch => {
    dispatch(stopMetricsLoaded(payload));
  };
}


export function get_markets_data(payload) {
    return dispatch => {
        dispatch(loadingMarkets());
        localForage.getItem(`markets`, function (err, value) {
            if (value) {
                dispatch(receivedMarkets(value));
                if (value.updated_at < moment().unix() - 28800) {
                  download_markets(dispatch);
                }
            }
            else {
                download_markets(dispatch)
            }
        });
    }
}

function download_jobs(dispatch) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/status_v2');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();

                    localForage.setItem(`jobs_${apiLabel}`, data, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedJobs(data));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}

function download_notifications(dispatch) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    const [url, headers] = buildAPIRequest('/api/reports/notifications');
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
        .then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    if (data && data.error) {
                      alertify.error("Response error");
                      console.log(data.error);
                      return
                    }
                    data.updated_at = moment().unix();

                    localForage.setItem(`notifications_${apiLabel}`, data, function (err) {
                        if (err) {
                            console.log(err);
                        }
                    });
                    dispatch(receivedNotifications(data));
                })
            } else if (response.status === 401) {
              logout();
              return;
            }
        })
        .catch((error) => { console.log(error) })
}

export function get_jobs_data(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingJobs());

        localForage.getItem(`jobs_${apiLabel}`, function (err, value) {
            if (value) {
                dispatch(receivedJobs(value));
                if (value.updated_at < moment().unix() - 300) {
                    download_jobs(dispatch);
                }
            }
            else {
                download_jobs(dispatch)
            }
        })
    }
}


export function get_notifications_data(payload) {
    const store = configureStore();
    const apiLabel = store.getState().conn.label;
    return dispatch => {
        dispatch(loadingNotifications());

        localForage.getItem(`notifications_${apiLabel}`, function (err, value) {
            if (value) {
                dispatch(receivedNotifications(value));
                if (value.updated_at < moment().unix() - 300) {
                    download_notifications(dispatch);
                }
            }
            else {
                download_notifications(dispatch)
            }
        })
    }
}


function refreshInstanceInfo(conn) {
    console.log("Sending event to refresh instance parks information")
    return {
        type: UPDATE_PARKS_INFO,
        conn
    }
}


export function downloadParks(filter = null, onLoad = null, instanceUrl = null) { 
  const activeFilter = (filter?.isAllParks && filter?.isAllParks === true) ? '' : 'filter=active';
  const strategyFilter = (filter?.strategies && filter?.strategies !== 'All') ? `&strategy=${filter.strategies}` : '';
  const contractFilter = (filter?.contracts && filter?.contracts !== 'All') ? `&contract=${filter.contracts}` : '';
  const [currentInstanceUrl, headers] = buildAPIRequest(
    `/api/windparks?${activeFilter}${strategyFilter}${contractFilter}`,
  );
  const url = instanceUrl ? `${instanceUrl}/api/windparks?${activeFilter}${strategyFilter}${contractFilter}` : currentInstanceUrl;
  fetch(url, { method: 'GET', headers, ...security_fetch_params })
    .then((response) => {
      if (response.ok) {
        response.json().then(data => {
          if (data && data.error) {
            alertify.error("Response error");
            console.log(data.error);
            return
          }
          (typeof onLoad === 'function') && onLoad(data)
          return data.data;
        })
      } else if (response.status === 401) {
        logout();
        return;
      }
      else {
        console.log('API response error', response);
      }
    })
    .catch((error) => console.log(error));
}


export function getParks(payload, flag) {
  const store = configureStore();
  const apiLabel = store.getState().conn.label;
  return (dispatch) => {
    const filter = {
      'isAllParks': payload.isAllParks ? true : false,
      'strategies': payload.strategies?.join(','),
      'contracts': payload.contracts?.join(','),
    };

    const onLoad = (data) => {
      data.updated_at = moment().unix();
      localForage.setItem(
        `parks_${filter.strategies}_${filter.contracts}_${payload.isAllParks}_${apiLabel}`,
        data,
        (err) => err && console.log(err)
      );
      if(flag === 'pnlAnalysis')
        dispatch(receivedParksPnlAnalysData(data, filter));
      else
        dispatch(receivedParksPnlPeriodData(data, filter));
    };

    dispatch(loadingParksData());
    localForage.getItem(`parks_${filter.strategies}_${filter.contracts}_${payload.isAllParks}_${apiLabel}`, (err, value) => {
      if (value) {
        const INTERVAL = 3600000;
        value.updated_at < moment().unix() - INTERVAL
          ? downloadParks(filter, onLoad)
          : flag === 'pnlAnalysis' ?
              dispatch(receivedParksPnlAnalysData(value, filter)) :
              dispatch(receivedParksPnlPeriodData(value, filter));
      } else {
        downloadParks(filter, onLoad);
      }
    });
  };
}


export async function pullParksFromInstance(curInst) {
  const store = configureStore();
  const conn = store.getState().conn;

  const onLoad = (data) => {
    curInst.parks = data.data;
    sessionStorage.setItem('conn', JSON.stringify(curInst));
    store.dispatch(refreshInstanceInfo(curInst));
  };

  await downloadParks(null, onLoad);
  return Promise.resolve(conn);
}

/**
 * Actions factory.
 * @returns {{type: string, receivedAt: number}}
 */
function loadingStructuredAlerts() {
    return {
        type: LOADING_STRUCTURED_ALERTS,
        receivedAt: Date.now()
    }
}

/**
 * Actions factory.
 * @returns {{type: string, receivedAt: number}}
 */
function loadingComplExcs() {
    return {
        type: LOADING_COMPL_EXCS,
        receivedAt: Date.now()
    }
}

/**
 * Actions factory.
 * @param alerts - received data
 * @param masterAlert - true if there any critical alert
 * @returns {{alerts: *, type: *, receivedAt: number}}
 */
function receivedStructuredAlerts(alerts, masterAlert) {
    return {
        type: RECEIVED_STRUCTURED_ALERTS,
        alerts: alerts,
        masterAlert: masterAlert,
        receivedAt: Date.now()
    }
}

/**
 * Actions factory.
 * @param alerts - received data
 * @param masterAlert - true if there any critical alert
 * @returns {{alerts: *, type: *, receivedAt: number}}
 */
function receivedComplExcs(compl_excs) {
    return {
        type: RECEIVED_COMPL_EXCS,
        compl_excs: compl_excs,
        receivedAt: Date.now()
    }
}

function receivedModelsData(parkModels) {
  return {
      type: RECEIVED_MODELS_DATA,
      parkModels: parkModels,
      receivedAt: Date.now()
  }
}

function receivedCategories(categories) {
  return {
      type: RECEIVED_CATEGORIES,
      categories: categories,
      receivedAt: Date.now()
  }
}

function loadingModelsData() {
  return {
      type: LOADING_MODEL_DATA,
      receivedAt: Date.now()
  }
}

function loadingCategories() {
  return {
      type: LOADING_CATEGORIES,
      receivedAt: Date.now()
  }
}

/**
 * Thunk.
 * @returns {Function}
 */
export function get_structured_alerts() {
    return dispatch => {
        dispatch(loadingStructuredAlerts());
        const [url, headers] = buildAPIRequest('/api/audit/structuredalerts');
        fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data && data.error) {
                          alertify.error("Response error");
                          console.log(data.error);
                          return
                        }
                        const masterAlert = data.data.some((alert) => alert.severity === 'high');
                        dispatch(receivedStructuredAlerts(data.data, masterAlert));
                    })
                } else if (response.status === 401) {
                  logout();
                  return;
                }
            })
            .catch((error) => { console.log(error) })
    }
}

/**
 * Thunk.
 * @returns {Function}
 */
export function get_compl_excs() {
    return dispatch => {
        dispatch(loadingComplExcs());
        const [url, headers] = buildAPIRequest('/api/audit/completenessalertexceptions');
        fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data && data.error) {
                          alertify.error("Response error");
                          console.log(data.error);
                          return
                        }
                        dispatch(receivedComplExcs(data.data));
                    })
                } else if (response.status === 401) {
                  logout();
                  return;
                }
            })
            .catch((error) => { console.log(error) })
    }
}

export function getModelsData() {
  const store = configureStore();
  const apiLabel = store.getState().conn.label;
  return dispatch => {
      dispatch(loadingModelsData());
      localForage.getItem(`models_${apiLabel}`, function (err, value) {
          if (value) {
              dispatch(receivedModelsData(value));
              if (value.updated_at < moment().unix() - 10000) {
                downloadModelsData(dispatch);
              }
          }
          else {
            downloadModelsData(dispatch)
          }
      });
  }
}

function downloadModelsData(dispatch) {
  const store = configureStore();
  const apiLabel = store.getState().conn.label;
  const [url, headers] = buildAPIRequest('/api/windparks/models_list');
  fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
          if (response.ok) {
              response.json().then(data => {
                  if (data && data.error) {
                    alertify.error("Response error");
                    console.log(data.error);
                    return
                  }
                  data.data.updated_at = moment().unix();
                  localForage.setItem(`models_${apiLabel}`, data.data, function (err) {
                      if (err) {
                          console.log(err);
                      }
                  });
                  dispatch(receivedModelsData(data.data));
              })
          } else if (response.status === 401) {
            logout();
            return;
          }
      })
      .catch((error) => { console.log(error) })
}

export function getCategories() {
  return dispatch => {
      dispatch(loadingCategories());
      localForage.getItem('dp_categories', function (err, value) {
          if (value) {
              dispatch(receivedCategories(value));
              if (value.updated_at < moment().unix() - 10000) {
                downloadCategories(dispatch);
              }
          }
          else {
            downloadCategories(dispatch)
          }
      });
  }
}

function downloadCategories(dispatch) {
  const [url, headers] = buildAPIRequest('/api/dp_categories');

  fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
          if (response.ok) {
              response.json().then(data => {
                  if (data && data.error) {
                    alertify.error("Response error");
                    console.log(data.error);;
                    return
                  }
                  data.data.updated_at = moment().unix();
                  localForage.setItem('dp_categories', data.data, function (err) {
                      if (err) {
                          console.log(err);
                      }
                  });
                  dispatch(receivedCategories(data.data));
              })
          }
      })
      .catch((error) => { console.log(error) })
}
