import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from '@material-table/core';
import { FormControl, Grid, Chip, Checkbox, TablePagination, TextField } from '@material-ui/core';
import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod
  from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';
import classNames from 'classnames';
import moment from "moment";
import alertify from "alertifyjs";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import LoginPage from "views/Login/Oops.jsx";
import { security_fetch_params } from "actions/index";
import { primaryColor } from 'assets/jss/material-dashboard-react';
import { getMarketData } from "utils/getDataMethods";
import { stocksChartOptions, makeSeries, xAxisInUTC } from 'variables/charts';
import * as helper from './AuditDataHelper';


const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.loggedIn,
    apiLabel: state.conn.label,
    date_from: state.auditdata.dateFrom,
    date_to: state.auditdata.dateTo,
  };
};

const AuditDataDetails = (props) => {
  const [auditData, setAuditData] = useState(null);
  const [acknowledged, setAcknowledged] = useState('Not acknowledged');
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [dateFrom, setDateFrom] = useState(props.date_from);
  const [dateTo, setDateTo] = useState(props.date_to);
  const [variableData, setVariableData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isRunningAudit, setIsRunningAudit] = useState(false);
  const variableDataRef = useRef('[]');

  const { market: marketName,  market_id, variable_id, variable_name: variableName, variable, auditor} = props.location.state;
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  HighchartsExportVisiblePeriod(Highcharts);
  Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');

  const getMarketAuditData = async (pageNum, rowsPerPage, acknowledged, date_from, date_to) => {
    const acknowledgedStr = acknowledged === 'Acknowledged' 
      ? '&acknowledged=true' 
      : acknowledged === 'Not acknowledged'
        ? '&acknowledged=false'
        : '';
    const dateMin = auditor === 'freshness' ? '' : `&date_min=${date_from}`
    const dateMax = auditor === 'freshness' ? '' : `&date_max=${date_to}`

    const queryStr = `?page=${pageNum}&size=${rowsPerPage}${acknowledgedStr}${dateMin}${dateMax}&auditor=${auditor}&order_by=-time`;

    try {
      const response = await fetch(`https://kube.sama.energy/gateway/api/markets/${market_id}/variables/${variable_id}/audits${queryStr}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        ...security_fetch_params,
      });
  
      if (response.ok) {
        const data = await response.json();
        setAuditData(data);
      } else {
        alertify.error('Error getting data!', 5);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const updateMarketAuditData = async (audit_id, value) => {
    try {
      const response = await fetch(`https://kube.sama.energy/gateway/api/market_data_audit_entries/${audit_id}`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "acknowledged": value
        }),
        ...security_fetch_params,
      });
  
      if (response.ok) {
        const data = await response.json();
        
        if (data.acknowledged) {
          alertify.success(`Audit from ${moment(data.time).format("YYYY-MM-DD HH:mm:ss")} acknowledged!`)
        } else if (!data.acknowledged) {
          alertify.error(`Audit from ${moment(data.time).format("YYYY-MM-DD HH:mm:ss")} is not acknowledged!`)
        }

        await getMarketAuditData(page, pageSize, acknowledged, dateFrom, dateTo);
      } else {
        alertify.error('Faild to update audit status!')
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getVariableData = async (market_id, variable, dateFrom, dateTo) => {
    const data = await getMarketData(
      market_id,
      variable,
      dateFrom,
      dateTo,
      props.apiLabel
    )
    let varData = [];
    if (data.data && data.data.length) {
      const startDate = moment.utc(dateFrom.format('YYYY-MM-DD')).unix() * 1000;
      const lastDate = moment.utc(dateTo.format('YYYY-MM-DD')).unix() * 1000;
      
      const interval = Math.min(...new Set(data.data[0].map((el, i) => {
        if (i === data.data[0].length - 1) {
          return el[0] - data.data[0][i - 1][0]
        }
        return Math.abs(el[0] - data.data[0][i + 1][0])
      })))
      const auditGaps = auditData.items.map(el => moment.utc(el.time).unix() * 1000);
      
      const fillDatesObject = () => {
        const obj = {}
        for(let timestamp = startDate; timestamp <= lastDate; timestamp += interval) {
          obj[timestamp] = null
        }
        return obj;
      }
  
      const datesObj = fillDatesObject();
      let dataObj = {};
      data.data[0].forEach(el => dataObj[el[0]] = el[1]);
      Object.keys(datesObj).map(key => datesObj[key] = dataObj[key] || null);
      const dataArr = Object.keys(datesObj).map(key => [parseInt(key), datesObj[key]]);
      const noDataArr = Object.keys(datesObj).map(key => [parseInt(key), typeof datesObj[key] === 'number' 
        ? null 
        : auditGaps.includes(parseInt(key)) 
          ? parseInt(key) 
          : null]);
      const minIndex = Math.min(...[
        dataArr.findIndex(el => typeof el[1] === 'number'), 
        noDataArr.findIndex(el => typeof el[1] === 'number')
      ]);
      const maxIndex = Math.max(...[
        dataArr.findLastIndex(el => typeof el[1] === 'number'), 
        noDataArr.findLastIndex(el => typeof el[1] === 'number')
      ]);
      
      varData = [dataArr, noDataArr, data.data[1]].map(arr => arr.filter((el, i) => i >= minIndex && i <= maxIndex));
    } else if (data.data && !data.data.length) {
      alertify.error(`No data for ${variable}!`, 5);
    } else {
      alertify.error(`Responce error!`, 5);
    }

    const varDataStr = JSON.stringify(varData);
    if (variableDataRef.current !== varDataStr) {
      variableDataRef.current = varDataStr;
      setVariableData(varData);
    }
    setIsLoadingData(false);
  }

  const handleRunAudit = async () => {
    const { auditor, market_id, variable_id } = props.location.state;
    setIsRunningAudit(true);
    const response = await fetch(
      `https://kube.sama.energy/gateway/api/markets/${market_id}/variables/${variable_id}/audits`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          auditor: auditor,
          date_interval: `absolute|${auditData.items[0].time}|${auditData.items[auditData.items.length - 1].time}`,
          granularity: 1,
          rule: '',
        }),
        ...security_fetch_params,
      }
    );
    if (response.status === 204) {
      alertify.success("Audit created!");
    } else {
      alertify.error("Error creating audit!");
    }
    getMarketAuditData(page, pageSize, acknowledged, dateFrom, dateTo);
  }

  useEffect(() => {
    let interval;

    if (isRunningAudit) {
      interval = setInterval(() => getMarketAuditData(page, pageSize, acknowledged, dateFrom, dateTo), 5000);
    }

    return () => clearInterval(interval);
  }, [isRunningAudit, dateFrom, dateTo])

  const handleChkChange = (e, value) => {
    updateMarketAuditData(e.target.id, value);
  }

  useEffect(() => {
      if (auditData) {
        let from = moment(props.date_from).startOf('day').unix();
        let to = moment(props.date_to).endOf('day').unix();
        const data = auditData.items.map((audit, index) => {
          if (audit.time && moment(audit.time).unix() < from) {
            from = moment(audit.time).unix();
          } else if (audit.time && moment(audit.time).unix() > to) {
            to = moment(audit.time).unix();   
          }
          return {
            id: index,
            created_at: moment(audit.created_at).format('YYYY-MM-DD HH:mm:ss'),
            updated_at: audit.updated_at ? moment(audit.updated_at).format('YYYY-MM-DD HH:mm:ss') : '-',
            auditor: audit.auditor,
            time: audit.time ? moment(audit.time).format('YYYY-MM-DD HH:mm:ss') : '-',
            acknowledged: (
              <Checkbox
                color="primary"
                id={`${audit.id}`}
                onChange={handleChkChange}
                checked={audit.acknowledged}
                value=""
              />
            ),
          }
        });
  
        getVariableData(market_id, variable, moment.utc(dateFrom), moment.utc(dateTo));

        setTableData(data);
        setIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditData])

  useEffect(() => {
    getMarketAuditData(1, pageSize, acknowledged, dateFrom, dateTo);

    setPage(1);
    setIsLoading(true);
    setIsLoadingData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acknowledged]);

  const acknowledgedOptions = ['All', 'Acknowledged', 'Not acknowledged'];

  const tableHeads = ['Auditor', 'Time', 'Created at', 'Updated at', 'Acknowledged'];

  const makeColumns = (tableHeads) =>
    tableHeads.map(head => {
      return {
        title: head,
        field: head.toLowerCase().replace(' ', '_'),
        width: 110,
        sorting: false,
      }
    });
  
  const handleAuditsToShowSelect = (e, v) => {
    setAcknowledged(v);
  }

  const renderOptions = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        label={option}
        size="small"
        {...getTagProps({ index })}
      />
    ));

  const chartOptions = useMemo(() => {
    return {
      ...stocksChartOptions({
        filename: `${marketName}, ${variableName} data audit`,
      }),
      ...xAxisInUTC,
      yAxis: [
        {
          title: {
            text: 'value',
          },
          min: null,
          max: null,
        },
        {
          title: {
            text: 'source timestamp, UTC',
          },
          type: 'datetime',
          opposite: true,
        },
      ],
      series: [
        ...(variableData
          ? variableData.map((graph, i) => {
              if (i === 2)
                return makeSeries(`${variableName} source time`, graph, {
                  yAxis: 1,
                  tooltip: {
                    // Note: Use regular function instead of arrow one
                    // to have TooltipFormatterContext as this
                    pointFormatter: function () {
                      return (
                        `<span style="color:${this.color}">\u25CF</span>` +
                        ' Source timestamp: <b>' +
                        moment.utc(this.y).format('Y-MM-DD HH:mm:SS') +
                        '</b><br/>'
                      );
                    },
                  },
                  dashStyle: 'Dot',
                  visible: false,
                });
              else if (i === 1)
                return makeSeries(`${variableName} gap`, graph, {
                  yAxis: 1,
                  tooltip: {
                    // Note: Use regular function instead of arrow one
                    // to have TooltipFormatterContext as this
                    pointFormatter: function () {
                      return (
                        `<span style="color:${this.color}">\u25CF</span>` +
                        ' Gap timestamp: <b>' +
                        moment.utc(this.y).format('Y-MM-DD HH:mm:SS') +
                        '</b><br/>'
                      );
                    },
                  },
                  color: 'red',
                  marker: {
                    enabled: true,
                    radius: 7
                  }
                });
              return makeSeries(`${variableName}`, graph, {
                connectNulls: true
              });
            })
          : []
        ),
      ],
    };
  }, [variableData])

  if (props.isLoggedIn) {
    return (
      <>
        <Card>
          <CardHeader color="primary">
            <h4 className={props.classes.cardTitleWhite}>{marketName}, {variableName} data audit</h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="acknowledged"
                    label="Audits to show"
                    options={acknowledgedOptions}
                    value={acknowledged}
                    getOptionLabel={(option) => option}
                    onChange={handleAuditsToShowSelect}
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2} xl={1}>
                <Button 
                  color='primary' 
                  className={props.classes.fullWidth}
                  onClick={() => {
                    handleRunAudit() 
                  }}
                >
                  Run Audit
                </Button>
              </Grid>
              <Grid item xs={12}>
                {isLoading ? (
                  <div className="loader">Loading...</div>
                ) : (
                  <MaterialTable
                    columns={makeColumns(tableHeads)}
                    data={tableData}
                    onRowsPerPageChange={setPageSize}
                    options={{
                      toolbar: false,
                      tableLayout: 'fixed',
                      maxBodyHeight: window.innerHeight - 280,
                      headerStyle: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        color: primaryColor,
                        fontSize: '1em',
                        padding: '12px 0',
                        textAlign: 'center',
                        fontWeight: 500,
                      },
                      cellStyle: {
                        textAlign: 'center'
                      },
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [25, 50, 100],
                      pageSize: pageSize,
                      draggable: false,
                      search: false,
                    }}
                    components={{
                      Pagination: (props) => (
                        <TablePagination
                          rowsPerPage={pageSize} 
                          rowsPerPageOptions={[25, 50, 100]}
                          page={page - 1}
                          count={auditData.total}
                          onPageChange={(e, num) => {
                            setPage(num + 1);
                            setIsLoadingData(true);
                            getMarketAuditData(num + 1, pageSize, acknowledged, dateFrom, dateTo);
                          }}
                          onRowsPerPageChange={(e) => {
                            setPageSize(e.target.value);
                            setIsLoadingData(true);
                            getMarketAuditData(page, e.target.value, acknowledged, dateFrom, dateTo);
                          }}
                        />
                      )
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4} md={4} lg={2}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                    inputProps={{
                      name: 'dateFrom',
                      id: 'dateFrom',
                    }}
                    label="From:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={2}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                    inputProps={{
                      name: 'dateTo',
                      id: 'dateTo',
                    }}
                    label="To:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <FormControl
                  className={classNames(
                    props.classes.formControl,
                    props.classes.fullWidth,
                    props.classes.marginBottom
                  )}
                >
                  <Button 
                    color='primary' 
                    onClick={() => {
                      getMarketAuditData(page, pageSize, acknowledged, dateFrom, dateTo)
                      setPage(1);
                      setIsLoadingData(true); 
                    }}
                  >
                    Refresh
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {isLoadingData ? (
                  <div className="loader">Loading...</div>
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'stockChart'}
                      options={chartOptions}
                      oneToOne={true}
                      immutable = {true}
                    />
                  )
                }
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </>
    );
  } else{
    return (<LoginPage />);
  }
};

const ConnectedAuditDataDetails = connect(mapStateToProps)(AuditDataDetails);
export default withStyles(helper.styles)(ConnectedAuditDataDetails);
