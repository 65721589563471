// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import DataExplore from "@material-ui/icons/Explore";
import Schedule from "@material-ui/icons/Schedule";
// import ContentPaste from "@material-ui/icons/ContentPaste";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import LocationOn from "@material-ui/icons/LocationOn";
import MultilineChart from "@material-ui/icons/MultilineChart";
import Notifications from "@material-ui/icons/Notifications";
import Settings from "@material-ui/icons/Settings";
import Store from "@material-ui/icons/Store";
import Locked from "@material-ui/icons/Lock";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";

// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import FrDataDashboardPage from "views/DataDashboard/FrDataDashboard";
import DeDataDashboardPage from "views/DataDashboard/DeDataDashboard";
import UkDataDashboardPage from "views/DataDashboard/UkDataDashboard";
import ItSouthDataDashboardPage from "views/DataDashboard/ItSouthDataDashboard";
import ItNorthDataDashboardPage from "views/DataDashboard/ItNorthDataDashboard";
import MarketsDataPage from "views/DataDashboard/MarketsData.jsx";
import FundamentalsDataPage from "views/Markets/Fundamentals.jsx";
import TradesDashboardPage from "views/TradesDashboard/TradesDashboard.jsx";
import ForecastPage from "views/Forecast/Forecast.jsx";
import ProfitLossReport from "views/Reports/ProfitLossReport.jsx";
import ProfitLossInterval from "views/Reports/ProfitLossInterval.jsx";
import Jobs from "views/Jobs/Jobs.jsx";
import JobLog from "views/Jobs/JobLog.jsx";
import OptimisationLog from "views/Optimisation/OptimisationLog.jsx";
import RTrader from "views/R-Trader/Main.jsx";
import Optimisation from "views/Optimisation/Optimisation.jsx";
import StructuredAlertsPage from "views/StructuredAlerts/StructuredAlerts.jsx";
import Config from "views/Config/Config.jsx";
import OverrideDA from "views/OverrideDA/OverrideDA.jsx";
import StopTradingPage from "views/StopTrading/StopTrading";

import ScheduleJobsList from "views/ScheduleJobs/ScheduleJobsList";
import ScheduleJobsDetails from "views/ScheduleJobs/ScheduleJobsDetails";

import TradingConnectorsList from "views/TradingConnectors/TradingConnectorsList";
import TradingConnectorsDetails from "views/TradingConnectors/TradingConnectorsDetails";

import Backtesting from "views/Backtesting/Backtesting.jsx";
import SessionResults from "views/Backtesting/SessionResults.jsx";

import NotificationsPage from "views/Notifications/Notifications.jsx";
import LoginPage from "views/Login/LoginForm.js";
import LoadingPage from "views/Loading/Loading.js";
import MetricsPage from "views/Markets/Metrics.jsx";
import Markets from "views/Markets/Markets.jsx";
import MarketData from "views/Markets/MarketData.jsx";
import FundamentalsAudit from "views/DataDashboard/FundamentalsData"
import MarketScores from "views/DataDashboard/MarketScores.jsx";

import PNLAnalysis from 'views/KPI/PNLAnalysis';
import Permissions from "views/Permissions/Permissions";

import DataAnalysis from 'views/DataAnalysis/DataAnalysis';
import DataAnalysisDetails from 'views/DataAnalysis/DataAnalysisDetails';
import TopStrategies from "views/KPI/Profit";
import AfrrAndMfrrAnonymousBids from "views/Markets/AfrrAndMfrrAnonymous.jsx";
import InstanceReport from "views/KPI/InstanceReport";
import AuditDashboard from "views/AuditData/AuditDashboard";
import AuditData from "views/AuditData/AuditData";
import AuditDataDetails from "views/AuditData/AuditDataDetails";
import ParksJobDetails from "views/KPI/ParksJobDetails";
import AuditDashboardSettings from "views/AuditData/AuditDashboardSettings";
import AuditAlerts from "views/AuditData/AuditAlerts";
import AuditAlertsDetails from "views/AuditData/AuditAlertsDetails";


export const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    hide: false,
    group: 'Data integrity',
  },
  {
    path: "/tradesdashboard",
    sidebarName: "Trades Dashboard",
    navbarName: "EPEX Trades",
    icon: "touch_app",
    component: TradesDashboardPage,
    hide: true,
  },
  {
    path: "/frdatadashboard",
    sidebarName: "French Dashboard",
    navbarName: "French Dashboard",
    icon: "view_module",
    component: FrDataDashboardPage,
    hide: false,
    group: 'Data integrity',
  },
  {
    path: "/dedatadashboard",
    sidebarName: "German Dashboard",
    navbarName: "German Dashboard",
    icon: "view_module",
    component: DeDataDashboardPage,
    hide: false,
    group: 'Data integrity',
  },
  {
    path: "/ukdatadashboard",
    sidebarName: "UK Dashboard",
    navbarName: "UK Dashboard",
    icon: "view_module",
    component: UkDataDashboardPage,
    hide: false,
    group: 'Data integrity',
  },
  {
    path: "/itsouthdatadashboard",
    sidebarName: "IT South Dashboard",
    navbarName: "IT South Dashboard",
    icon: "view_module",
    component: ItSouthDataDashboardPage,
    hide: false,
    group: 'Data integrity',
  },
  {
    path: "/itnorthdatadashboard",
    sidebarName: "IT North Dashboard",
    navbarName: "IT North Dashboard",
    icon: "view_module",
    component: ItNorthDataDashboardPage,
    hide: false,
    group: 'Data integrity',
  },
  {
    path: "/auditdata",
    sidebarName: "Markets Dashboard",
    navbarName: "Markets Dashboard",
    icon: DataExplore,
    component: MarketsDataPage,
    hide: false,
    group: 'Data integrity',
  },
  {
    path: "/markets",
    sidebarName: "All Markets",
    navbarName: "All Markets",
    icon: LocationOn,
    component: Markets,
    hide: false,
    group: 'Market Data',
  },
  {
    path: "/fundamentals",
    sidebarName: "Forecast Data",
    navbarName: "Forecast Data",
    icon: Store,
    component: FundamentalsDataPage,
    hide: false,
    group: 'Market Data',
  },
  {
    path: "/rtrader",
    sidebarName: "Live EPEX Data",
    navbarName: "Live EPEX Data",
    icon: TransferWithinAStation,
    component: RTrader,
    hide: false,
    group: 'Market Data',
  },
  {
    path: "/profitlossdaily",
    sidebarName: "Profit and Loss Daily",
    navbarName: "Profit and Loss Daily",
    icon: "content_paste",
    component: ProfitLossReport,
    hide: true,
    parent: "/profitlossperiod"
  },
  {
    path: "/data_analysis",
    sidebarName: "Data Analysis",
    navbarName: "Data Analysis",
    icon: BubbleChart,
    component: DataAnalysis,
    hide: false,
    group: 'Market Data',
  },
  {
    path: "/data_analysis_details",
    sidebarName: "Data Analysis Details",
    navbarName: "Data Analysis Details",
    icon: BubbleChart,
    component: DataAnalysisDetails,
    hide: true,
    parent: "/data_analysis"
  },
  {
    path: "/metrics",
    sidebarName: "Imb Price Analysis",
    navbarName: "Metrics",
    icon: MultilineChart,
    component: MetricsPage,
    hide: false,
    group: 'Market Data',
  },
  {
    path: "/afrr&mfrrbids",
    sidebarName: "aFRR and mFRR bids",
    navbarName: "aFRR and mFRR anonymous bids",
    icon: MultilineChart,
    component: AfrrAndMfrrAnonymousBids,
    hide: false,
    group: 'Market Data',
  },
  {
    path: "/profitlossperiod",
    sidebarName: "P&L Period",
    navbarName: "P&L Period",
    icon: "euro_symbol",
    component: ProfitLossInterval,
    hide: false,
    group: 'Profitability analysis',
  },
  {
    path: "/pnl_analysis",
    sidebarName: "P&L Analysis",
    navbarName: "P&L Analysis",
    icon: BubbleChart,
    component: PNLAnalysis,
    hide: false,
    group: 'Profitability analysis',
  },
  {
    path: "/instance_report",
    sidebarName: "Instance Report",
    navbarName: "Instance Report",
    icon: BubbleChart,
    component: InstanceReport,
    hide: false,
    group: 'Profitability analysis',
  },
  {
    path: "/parks_job_details",
    sidebarName: "Parks Job Details",
    navbarName: "Parks Job Details",
    icon: BubbleChart,
    component: ParksJobDetails,
    hide: false,
    group: 'Profitability analysis',
  },
  {
    path: "/topstrategies",
    sidebarName: "Top strategies",
    navbarName: "Top strategies",
    icon: BubbleChart,
    component: TopStrategies,
    hide: false,
    group: 'Profitability analysis',
  },
  {
    path: "/forecast",
    sidebarName: "Production Forecast",
    navbarName: "Forecast",
    icon: Schedule,
    component: ForecastPage,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/optimisation",
    sidebarName: "Park Info",
    navbarName: "Park Info",
    icon: BubbleChart,
    component: Optimisation,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/config",
    sidebarName: "Park Config",
    navbarName: "Park Config",
    icon: BubbleChart,
    component: Config,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/overrideda",
    sidebarName: "Override DA",
    navbarName: "Override DA",
    icon: BubbleChart,
    component: OverrideDA,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/tradeconn-details",
    sidebarName: "Trading Connectors Details",
    navbarName: "Trading Connectors Details",
    icon: Notifications,
    component: TradingConnectorsDetails,
    hide: true,
  },
  {
    path: "/tradingconnectors",
    sidebarName: "Trading Connectors",
    navbarName: "Trading Connectors",
    icon: Notifications,
    component: TradingConnectorsList,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/schedulejobs",
    sidebarName: "Schedule Jobs",
    navbarName: "Schedule Jobs",
    icon: Notifications,
    component: ScheduleJobsList,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/schedjob-details",
    sidebarName: "Schedule Jobs Details",
    navbarName: "Schedule Jobs Details",
    icon: Notifications,
    component: ScheduleJobsDetails,
    hide: true,
  },
  {
    path: "/backtesting",
    sidebarName: "Backtesting",
    navbarName: "Backtesting",
    icon: SettingsBackupRestore,
    component: Backtesting,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/battery_config",
    sidebarName: "Battery Configuration",
    navbarName: "Battery Configuration",
    icon: BubbleChart,
    component: Config,
    hide: false,
    group: 'Battery Optimization',
  },
  {
    path: "/battery_trading_report",
    sidebarName: "Battery Trading Report",
    navbarName: "Battery Trading Report",
    icon: "euro_symbol",
    component: ProfitLossInterval,
    hide: false,
    group: "Battery Optimization",
  },
  {
    path: "/battery_trading_analysis",
    sidebarName: "Battery Trading Analysis",
    navbarName: "Battery Trading Analysis",
    icon: BubbleChart,
    component: PNLAnalysis,
    hide: false,
    group: "Battery Optimization",
  },
  {
    path: "/jobs",
    sidebarName: "Jobs",
    navbarName: "Jobs",
    icon: LibraryBooks,
    component: Jobs,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/notifications",
    sidebarName: "Notifications",
    navbarName: "Notifications",
    icon: Notifications,
    component: NotificationsPage,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/audit_dashboard",
    sidebarName: "Customer Delivery Dashboard",
    navbarName: "Customer Delivery Dashboard",
    icon: Notifications,
    component: AuditDashboard,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/auditmarketdata",
    sidebarName: "Market Data Audit",
    navbarName: "Market Data Audit",
    icon: Notifications,
    component: AuditData,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/auditmarketdatadetails",
    sidebarName: "Audit Market Data Details",
    navbarName: "Audit Market Data Details",
    icon: Notifications,
    component: AuditDataDetails,
    hide: true,
  },
  {
    path: "/login",
    sidebarName: "Login",
    navbarName: "Login",
    icon: Locked,
    component: LoginPage,
    hide: true
  },
  {
    path: "/loading",
    sidebarName: "Loading",
    navbarName: "Loading",
    icon: Locked,
    component: LoadingPage,
    hide: true
  },
  {
    path: "/job_log",
    sidebarName: "Job Log",
    navbarName: "Job Log",
    icon: Notifications,
    component: JobLog,
    hide: true,
    parent: "/jobs",
  },
  {
    path: "/optimisation_log",
    sidebarName: "Optimisation Log",
    navbarName: "Optimisation Log",
    icon: Notifications,
    component: OptimisationLog,
    hide: true,
    parent: "/optimisation"
  },
  {
    path: "/market_variables",
    sidebarName: "Market Data",
    navbarName: "Market Data",
    icon: Notifications,
    component: MarketData,
    hide: true,
    parent: "/markets"
  },
  {
    path: "/session_results",
    sidebarName: "Session Results",
    navbarName: "Session Results",
    icon: Notifications,
    component: SessionResults,
    hide: true,
    parent: "/backtesting"
  },
  {
    path: "/marketaudit",
    sidebarName: "MarketScores",
    navbarName: "MarketScores",
    icon: Notifications,
    component: MarketScores,
    hide: true,
    parent: "/auditdata"
  },
  {
    path: "/fundamentalsaudit",
    sidebarName: "Fundamentals Audit",
    navbarName: "Fundamentals Audit",
    icon: Notifications,
    component: FundamentalsAudit,
    hide: true,
    parent: "/fundamentals"
  },
  {
    path: "/structuredalerts",
    sidebarName: "Structured Alerts",
    navbarName: "Structured Alerts",
    icon: Notifications,
    component: StructuredAlertsPage,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/stoptrading",
    sidebarName: "ID Trading Status",
    navbarName: "ID Trading Status",
    icon: Settings,
    component: StopTradingPage,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/permissions",
    sidebarName: "Sidebar settings",
    navbarName: "Sidebar settings",
    icon: Settings,
    component: Permissions,
    hide: false,
    group: 'Settings',
  },
  {
    path: "/audit_settings",
    sidebarName: "Audit settings",
    navbarName: "Audit Dashboard settings",
    icon: Settings,
    component: AuditDashboardSettings,
    hide: false,
    group: 'Settings',
  },
  {
    path: "/audit_alerts",
    sidebarName: "Audit Alerts",
    navbarName: "Audit Alerts",
    icon: Notifications,
    component: AuditAlerts,
    hide: false,
    group: 'Settings',
  },
  {
    path: "/audit_alert_details",
    sidebarName: "Audit Alert Details",
    navbarName: "Audit Alert Details",
    icon: Notifications,
    component: AuditAlertsDetails,
    hide: true,
    parent: '/audit_alerts',
  },
  { redirect: true, path: "/", navbarName: "Redirect" }
];

export const filterRoutes = (conn) => {
  // Show Instance Report && Customer Delivery Dashboard only for permited instances
  if (!conn.showInstanceReport && !conn.showAuditDashboard) {
    return dashboardRoutes.filter((route) => {
      return route.sidebarName !== 'Instance Report' && 
        route.sidebarName !== 'Parks Job Details' && 
        route.sidebarName !== 'Customer Delivery Dashboard' &&
        route.sidebarName !== 'Audit settings' &&
        route.sidebarName !== 'Audit Alerts' &&
        route.sidebarName !== 'Audit Alert Details'
    })
  } else if(!conn.showInstanceReport) {
    // Show Instance Report only for permited instances
    return dashboardRoutes.filter((route) => {
      return route.sidebarName !== 'Instance Report' && route.sidebarName !== 'Parks Job Details';
    })
  } else {
    return dashboardRoutes;
  }
};
