import { buildAPIRequest, buildKUBERequest, security_fetch_params } from "actions/index";
import moment from "moment";
import localForage from "localforage";
import { logout } from "utils/auth";

import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/default.min.css";
import alertify from "alertifyjs";


export const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  fullWidth: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  marginBottomL: {
    marginBottom: '2rem',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  hidden: {
    visibility: 'hidden',
  },
  flexRow: {
    flexDirection: 'row',
    gap: '10px',
  },
  searchWrapper: {
    display: 'flex',
    justifyContent: 'end',
    padding: '0 24px 16px 24px',
  },
  statusContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    gap: '10px', 
    alignItems: 'center',
  },
  wrapper: {
    display: 'inline-block',
    padding: '2px',
    boxSizing: 'border-box',
  },
};

export const getAuditInstances = async () => {
  const response = await fetch('https://kube.sama.energy/gateway/api/audit_instances', {
    method: 'GET',
    headers: {
      "Accept": 'application/json',
    },
    ...security_fetch_params
  })

  if (response.ok) {
    const data = await response.json();
    return data
  } else if (response.status === 401) {
    logout();
    return []; 
  } else {
    alertify.error('Error getting instances', 5);
    return [];
  }
}

export const getInstanceParks = async (instance_id) => {
  const response = await fetch(`https://kube.sama.energy/gateway/api/audit_instances/${instance_id}/parks`, {
    method: 'GET',
    headers: {
      "Accept": 'application/json'
    },
    ...security_fetch_params
  })

  if (response.ok) {
    const data = await response.json();
    return data;
  } else if (response.status === 401) {
    logout();
    return [];
  } else {
    alertify.error("Error geting instance parks", 5);
    return [];
  }
}